import React from "react";
import useFetch from "../../hooks/useFetch";
import DayValueChart from "../../components/Chart/DayValueChart";

function Dashboard() {
  const { data: metrics, loading: metricsLoading } = useFetch(
    "/v1/admin/dashboard/metrics",
  );
  const { data: placeCount, loading: placeCountLoading } = useFetch(
    "/v1/admin/dashboard/countryPlaceCount",
  );
  const { data: newUserMetrics, loading: newUserMetricLoading } = useFetch(
    "/v1/admin/dashboard/users/new",
  );
  const { data: newPlaceMetrics, loading: newPlaceMetricLoading } = useFetch(
    "/v1/admin/dashboard/places/new",
  );

  return (
    <div>
      <div className="flex justify-between mb-6 items-center">
        <span className="font-bold text-lg">DASHBOARD</span>
      </div>

      <div className="w-full mb-6">
        <div className="font-bold mb-4">General information</div>
        {metricsLoading ? (
          <span>Loading...</span>
        ) : (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-5 2xl:gap-7.5">
            <DashboardCard
              title="Active Places"
              description="Total amount"
              content={metrics.activePlaces}
            />
            <DashboardCard
              title="Blocked Places"
              description="Total amount"
              content={metrics.blockedPlaces}
            />
            <DashboardCard
              title="Users"
              description="Total amount"
              content={metrics.totalUsers}
            />
            <DashboardCard
              title="New Users"
              description="For the last 30 days"
              content={metrics.newUsers}
            />
          </div>
        )}
      </div>

      <div className="w-full mb-8">
        <div className="font-bold mb-4">Charts</div>
        {newUserMetricLoading || newPlaceMetricLoading ? (
          <span>Loading...</span>
        ) : (
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-6">
            <DashboardChart
              data={newPlaceMetrics}
              name="New Places for the last 30 days"
            />
            <DashboardChart
              data={newUserMetrics}
              name="New Users for the last 30 days"
            />
          </div>
        )}
      </div>

      <div className="w-full mb-8">
        <div className="font-bold mb-4">Number of places in the country</div>
        {placeCountLoading ? (
          <span>Loading...</span>
        ) : (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-5 2xl:gap-7.5">
            {placeCount.countryPlaceCounts
              .slice()
              .sort((a, b) => b.placeCount - a.placeCount)
              .map((placeCount, index) => (
                <DashboardCard
                  key={index}
                  title={placeCount.countryName}
                  content={placeCount.placeCount}
                  description={placeCount.countryCode}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

const DashboardChart = ({ data, name }) => (
  <div className="rounded-sm shadow-md bg-white p-5">
    <DayValueChart data={data} name={name} />
  </div>
);

const DashboardCard = ({ title, description, content }) => (
  <div className="rounded-sm shadow-md bg-white p-5">
    <div className="flex flex-col">
      <span className="font-bold text-sm">{title}</span>
      <span className="text-xs text-gray-500">{description}</span>
      <span className="mt-2 text-base">{content}</span>
    </div>
  </div>
);

export default Dashboard;
