import React, { useState } from "react";
import { toast } from "react-toastify";
import { MdAddAPhoto } from "react-icons/md";

function AddImageModal({ title, onSubmit, onCancel }) {
  const [imageUrl, setImageUrl] = useState("");
  const [showError, setShowError] = useState(false);

  const handleAddImage = (event) => {
    event.preventDefault();
    if (imageUrl) {
      onSubmit(imageUrl);
      onCancel();
      toast.success("Image has been added!");
    } else {
      setShowError(true);
    }
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 items-center p-4">
          <div className="relative transform overflow-hidden rounded-lg w-full bg-white shadow-xl transition-all mx-auto my-8 max-w-md p-4">
            <div className="bg-white py-2">
              <div className="flex flex-col justify-center">
                <div className="flex flex-col gap-4 mt-2 w-full">
                  <h3
                    className="text-sm  text-center my-2 font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                  <div className="flex justify-center w-full">
                    <div className="flex w-64 h-44 bg-gray-300 rounded-lg">
                      {imageUrl ? (
                        <div
                          style={{ backgroundImage: `url(${imageUrl})` }}
                          className="w-full h-full relative rounded-lg bg-center bg-cover"
                        ></div>
                      ) : (
                        <div className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-md bg-gray-50">
                          <div className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50">
                            <MdAddAPhoto size="32" className="text-gray-600" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    name="name"
                    onChange={(event) => setImageUrl(event.target.value)}
                    value={imageUrl}
                    className="custom-input"
                    placeholder="Provide image url"
                    required
                  />
                  {showError && (
                    <span className="text-red-600 text-xs text-center">
                      Provide image URL
                    </span>
                  )}
                </div>
              </div>
            </div>
            {(onCancel || onSubmit) && (
              <div className="grid text-sm grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-4">
                {onCancel && (
                  <button
                    type="button"
                    onClick={onCancel}
                    className="p-1 hover:border-gray-400 duration-300 border rounded-md w-full"
                  >
                    Cancel
                  </button>
                )}
                {onSubmit && (
                  <button
                    type="button"
                    onClick={handleAddImage}
                    className="p-1 bg-lime-500 hover:bg-lime-600 duration-300 text-white rounded-md w-full"
                  >
                    Add
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddImageModal;
