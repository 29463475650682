import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import useFetch from "../../hooks/useFetch";
import { useAuthHeader } from "react-auth-kit";
import Countries from "../Countries/Countries";

function PlaceGeneralInfo({ place, onChange }) {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();

  const { data: categories, loading: categoriesLoading } =
    useFetch(`/v1/categories`);
  const [types, setTypes] = useState([]);

  const handeGeneralInfoChange = (event) => {
    const { name, value } = event.target;
    onChange((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  const handleCountryUpdate = (country) => {
    onChange((prevEntity) => ({ ...prevEntity, countryCode: country }));
  };

  useEffect(() => {
    const fetchTypes = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/categories/${place.categoryId}/types`,
        {
          headers: { Authorization: authHeaderValue },
        },
      );
      const jsonData = await response.json();
      setTypes(jsonData);
    };

    if (place.categoryId) {
      fetchTypes();
    }
  }, [place, categories, authHeaderValue]);

  return categoriesLoading ? (
    <span>Loading...</span>
  ) : (
    <>
      <div className="font-semibold text-sm text-center">
        GENERAL INFORMATION
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Name</label>
        <input
          type="text"
          name="name"
          onChange={handeGeneralInfoChange}
          value={place.name}
          className="custom-input"
          placeholder="Notification title"
          required
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">
          Place category
          <select
            required
            name="categoryId"
            onChange={handeGeneralInfoChange}
            value={place.categoryId}
            className="custom-select"
          >
            <option value="" disabled>
              Select category
            </option>
            {categories.map((category, index) => (
              <option key={index} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
        </label>
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">
          Place type
          <select
            name="typeId"
            value={place.typeId || ""}
            onChange={handeGeneralInfoChange}
            className="custom-select"
          >
            <option value="" disabled>
              Select type
            </option>
            {types &&
              types.length !== 0 &&
              types.map((type, index) => (
                <option key={index} value={type.id}>
                  {type.name}
                </option>
              ))}
          </select>
          <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
        </label>
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">
          Place price
          <select
            name="price"
            onChange={handeGeneralInfoChange}
            value={place.price}
            className="custom-select"
          >
            <option value="" disabled>
              Select price
            </option>
            {["$", "$$", "$$$", "$$$$"].map((value, index) => (
              <option key={index} value={index + 1}>
                {value}
              </option>
            ))}
          </select>
          <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
        </label>
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Address</label>
        <input
          type="text"
          name="address"
          onChange={handeGeneralInfoChange}
          value={place.address}
          className="custom-input"
          placeholder="Notification title"
          required
        />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">Country</label>
        <Countries value={place.countryCode} setValue={handleCountryUpdate} />
      </div>
      <div className="relative">
        <label className="block text-[10px] text-gray-700">City</label>
        <input
          type="text"
          name="city"
          onChange={handeGeneralInfoChange}
          value={place.city}
          className="custom-input"
          placeholder="Notification title"
          required
        />
      </div>
    </>
  );
}

export default PlaceGeneralInfo;
