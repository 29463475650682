import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaTrashAlt } from "react-icons/fa";
import AddImageModal from "../Modal/AddImageModal";
import { MdAddAPhoto } from "react-icons/md";
import CustomSlider from "./CustomSlider";

function EditSlider({ entity, setEntity }) {
  const [showModal, setShowModal] = useState(false);

  const handleImageAdding = (photoUrl) => {
    setEntity((prevEntity) => {
      const newPhotos = [...prevEntity.photos];
      newPhotos[prevEntity.photos.length] = photoUrl;
      return {
        ...prevEntity,
        photos: newPhotos,
      };
    });
  };

  const handleImageDeletion = (index) => {
    setEntity((prevEntity) => {
      const newPhotos = [...prevEntity.photos];
      newPhotos.splice(index, 1);
      return {
        ...prevEntity,
        photos: newPhotos,
      };
    });
  };

  return (
    <>
      {showModal && (
        <AddImageModal
          title={"Add new image"}
          onCancel={() => setShowModal(false)}
          onSubmit={handleImageAdding}
        />
      )}
      <CustomSlider>
        {entity.photos.map((imageUrl, index) => (
          <div key={index} className="group relative">
            <div
              style={{ backgroundImage: `url(${imageUrl})` }}
              className="w-full h-56 relative rounded-lg bg-center bg-cover origin-top duration-300"
            ></div>
            <div className="absolute flex flex-col rounded-lg items-center justify-center w-full h-full scale-y-0 bottom-0 origin-top group-hover:scale-100 duration-100 p-8 bg-white bg-opacity-70">
              <div
                onClick={() => handleImageDeletion(index)}
                className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50 cursor-pointer"
              >
                <FaTrashAlt size="32" className="text-gray-600" />
              </div>
              <div className="text-sm mt-2">Remove</div>
            </div>
          </div>
        ))}
        <div className="flex h-56 bg-gray-300 rounded-lg">
          <div className="flex flex-col text-sm items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg bg-gray-50">
            <div
              onClick={() => setShowModal(true)}
              className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50 cursor-pointer"
            >
              <MdAddAPhoto size="32" className="text-gray-600" />
            </div>
            <div className="text-sm mt-2">Add</div>
          </div>
        </div>
      </CustomSlider>
    </>
  );
}

export default EditSlider;
