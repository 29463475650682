import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import PaginationControl from "../../components/Table/PaginationControl";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Avatar from "../../components/Images/Avatar";
import { IoIosArrowDown } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
import useDebouncedSearchInput from "../../hooks/useDebouncedSearch";
import { FaExclamationTriangle } from "react-icons/fa";

const Users = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [searchSetting, setSearchSetting] = useState({
    page: 0,
    size: 10,
    sortBy: "createdAt",
    sortDirection: "DESC",
    search: "",
    status: "active",
    role: "",
    onlyWithReports: false,
  });
  const { data: roles, loading: roleLoading } = useFetch(`/v1/admin/roles`);
  const { data, loading } = useFetch(
    `/v1/admin/users?page=${searchSetting.page}&pageSize=${searchSetting.size}&sortBy=${searchSetting.sortBy}&order=${searchSetting.sortDirection}&onlyWithReports=${searchSetting.onlyWithReports}&search=${searchSetting.search}&status=${searchSetting.status}&role=${searchSetting.role}`,
  );

  useDebouncedSearchInput(searchInput, setSearchSetting, "search");

  const handleSearchSettingsChange = (event) => {
    const { name, value } = event.target;
    setSearchSetting((prevEntity) => ({
      ...prevEntity,
      [name]: value,
      page: 0,
    }));
  };

  return roleLoading ? (
    <span>Loading...</span>
  ) : (
    <div>
      <div className="flex justify-between mb-6 items-center">
        <span className="font-bold text-lg">USERS</span>
      </div>
      <div className="relative mb-6">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <BiSearch className="text-black" />
        </div>
        <input
          type="search"
          onChange={(event) => setSearchInput(event.target.value)}
          className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:outline-none shadow-sm"
          placeholder="Search user..."
        />
      </div>
      <div className="bg-white p-6 rounded-md shadow-md">
        <div className="grid justify-start grid-cols-1 xl:grid-cols-5 lg:grid-cols-2 gap-x-8 gap-2 mb-6">
          <div className="relative">
            <label className="relative text-[10px] text-gray-700">
              User group
              <select
                name="role"
                defaultValue=""
                onChange={handleSearchSettingsChange}
                className="custom-select"
              >
                <option value="">All</option>
                {roles.map((role, index) => (
                  <option key={index} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
              <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
            </label>
          </div>
          <div className="relative">
            <label className="relative text-[10px] text-gray-700">
              Status
              <select
                name="status"
                defaultValue="active"
                onChange={handleSearchSettingsChange}
                className="custom-select"
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="blocked">Blocked</option>
                <option value="deleted">Deleted</option>
              </select>
              <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
            </label>
          </div>
          <div className="relative">
            <label className="relative text-[10px] text-gray-700">
              Sort by
              <select
                id="model"
                name="sortBy"
                defaultValue="createdAt"
                onChange={handleSearchSettingsChange}
                className="custom-select"
              >
                <option value="email">Email</option>
                <option value="id">ID</option>
                <option value="createdAt">Recently created</option>
                <option value="updatedAt">Recently updated</option>
              </select>
              <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
            </label>
          </div>
          <div className="relative">
            <label className="relative text-[10px] text-gray-700">
              Order by
              <select
                name="sortDirection"
                defaultValue="DESC"
                onChange={handleSearchSettingsChange}
                className="custom-select"
              >
                <option value="DESC">Descending</option>
                <option value="ASC">Ascending</option>
              </select>
              <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
            </label>
          </div>
          <div className="items-center flex gap-4 md:gap-6 pt-5">
            <div className="flex gap-2 items-center">
              <input
                onChange={() =>
                  setSearchSetting({
                    ...searchSetting,
                    onlyWithReports: !searchSetting.onlyWithReports,
                  })
                }
                checked={searchSetting.onlyWithReports}
                type="checkbox"
                className="custom-checkbox"
              />
              <span className="text-sm">Only with claims</span>
            </div>
          </div>
        </div>
        {loading ? (
          <span>Loading...</span>
        ) : (
          <div className="mb-6">
            <table className="w-full">
              <thead className="text-gray-700 text-xs bg-gray-100 ">
                <tr>
                  <TableHeader name="ID" />
                  <TableHeader name="EMAIL" />
                  <TableHeader name="FULL NAME" />
                  <TableHeader name="CLAIMS" />
                  <TableHeader name="STATUS" />
                  <TableHeader name="USER GROUPS" />
                </tr>
              </thead>
              <tbody>
                {data.data.map((user, i) => (
                  <tr
                    key={i}
                    onClick={() => navigate(`/users/${user.id}`)}
                    className="border-b text-xs hover:bg-gray-50 duration-300 hover:cursor-pointer"
                  >
                    <TableRow value={user.id} />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <Avatar image={user.photoUrl} />
                          <div>{user.email}</div>
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <div>
                            {user.firstName} {user.lastName}
                          </div>
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        user.reports.length !== 0 && (
                          <div className="flex gap-1 items-center">
                            <FaExclamationTriangle className="text-red-600" />
                            {user.reports.length}
                          </div>
                        )
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <span
                            className={`p-1 rounded-full ${user.isDeleted ? "bg-red-500" : user.isBlocked ? "bg-yellow-500" : "bg-green-500"}`}
                          />
                          {user.isDeleted
                            ? "Deleted"
                            : user.isBlocked
                              ? "Blocked"
                              : "Active"}
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex gap-1">
                          {user.roles.map((role, index) => (
                            <span
                              className={`${role.id === 1 ? "bg-sky-100" : role.id === 2 ? "bg-red-100" : "bg-lime-100"} text-[10px] rounded-lg px-2`}
                              key={index}
                            >
                              {role.name}
                            </span>
                          ))}
                        </div>
                      }
                    />
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationControl
              searchSetting={searchSetting}
              setSearchSetting={setSearchSetting}
              paginationSettings={data.pageMetadata}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
