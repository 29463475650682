import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { AiFillDelete } from "react-icons/ai";
import Avatar from "../../components/Images/Avatar";
import DraftReviews from "./DraftReviews";
import Select from "react-select";
import EditSlider from "../../components/Slider/EditSlider";
import PlaceContacts from "../../components/Place/PlaceContacts";
import PlaceGeneralInfo from "../../components/Place/PlaceGeneralInfo";
import SearchTags, {
  createOption,
} from "../../components/SearchTags/SearchTags";
import { toast } from "react-toastify";
import { useAuthHeader } from "react-auth-kit";
import ViewSlider from "../../components/Slider/ViewSlider";
import { BsSaveFill } from "react-icons/bs";
import {
  addSearchTagsToPlace,
  validatePlaceBeforeCreate,
} from "../../utils/PlaceUtils";
import LocationUpdate from "../../components/Map/LocationUpdate";

function convertToPlace(draft) {
  return {
    name: draft.name,
    city: draft.city,
    countryCode: draft.countryCode
      ? createOption(draft.country, draft.countryCode)
      : null,
    address: draft.address,
    lon: draft.lon,
    lat: draft.lat,
    photos: draft.photos,
    price: "",
    typeId: "",
    categoryId: "",
    tagIds: [],
    contacts: {
      phone: "",
      website: "",
      instagram: "",
      facebook: "",
      telegram: "",
      linkedin: "",
      tiktok: "",
    },
  };
}

function convertToRequest(draft, placeRequest) {
  return {
    createdByUserId: draft.createdBy.id,
    place: {
      ...placeRequest,
      tagIds: placeRequest.tagIds.map((tag) => tag.value),
      countryCode: placeRequest.countryCode.value,
    },
    review: {
      rating: draft.rating,
      comment: draft.review,
    },
  };
}

function DraftDetails() {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const location = useLocation();
  const navigate = useNavigate();
  const draft = location.state ? location.state.draft : null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [placeRequest, setPlaceRequest] = useState(convertToPlace(draft));
  const [placeSearchTags, setPlaceSearchTags] = useState([]);
  const { data: tags, loading: tagsLoading } = useFetch(`/v1/tags`);

  useEffect(() => {
    if (draft) {
      setPlaceRequest(convertToPlace(draft));
    }
  }, [draft]);

  const handeGeneralContactsChange = (event) => {
    const { name, value } = event.target;
    setPlaceRequest((prevEntity) => ({
      ...prevEntity,
      contacts: { ...prevEntity.contacts, [name]: value },
    }));
  };

  const createPlace = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/places/draft/${draft.id}/create`,
      {
        method: "POST",
        body: JSON.stringify(convertToRequest(draft, placeRequest)),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      toast.error("Couldn't create place: " + response.status);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      validatePlaceBeforeCreate(placeRequest);
    } catch (error) {
      toast.warning(error.message);
      setIsSubmitting(false);
      return;
    }
    try {
      const savedPlace = await createPlace();
      await addSearchTagsToPlace(
        savedPlace.id,
        placeSearchTags,
        authHeaderValue,
      );
      toast.success("Place has been created");
      navigate(`/places/${savedPlace.id}`);
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDismissPlace = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/places/draft/${draft.id}/dismiss`,
      {
        method: "POST",
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.ok) {
      toast.success("Place has been deleted!");
      navigate(`/drafts`);
      setIsSubmitting(false);
    } else {
      toast.error("Couldn't delete draft place");
      setIsSubmitting(false);
    }
  };

  return tagsLoading || !draft ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-6xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={"/drafts"}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>DRAFT:</span>
            <span>{draft.id}</span>
          </div>
        </div>
        {draft.status === "NEW" && (
          <div className="flex items-center gap-4">
            <button
              className={`block-btn`}
              disabled={isSubmitting}
              onClick={handleDismissPlace}
            >
              <AiFillDelete />
              DISMISS
            </button>
            <button
              className={`submit-btn`}
              type="submit"
              disabled={isSubmitting}
              form="create-draft-place-form"
            >
              <BsSaveFill />
              CREATE
            </button>
          </div>
        )}
      </div>
      <div className=" grid grid-cols-5 gap-8">
        <div className="flex flex-col col-span-5 xl:col-span-3 gap-8">
          <div className="rounded-md shadow-md bg-white p-8">
            <div className="flex justify-between">
              <Link
                to={`/users/${draft.createdBy.id}`}
                className="flex items-center gap-4"
              >
                <Avatar image={draft.createdBy.photoUrl} />
                <div className="hidden text-left lg:block">
                  <div className="block text-sm font-medium text-black">
                    {draft.createdBy.firstName} {draft.createdBy.lastName}
                  </div>
                  <div className="block text-xs text-gray-600">
                    {new Date(draft.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </Link>
              <div className="flex items-center gap-2 text-sm">
                <div
                  className={`p-1 rounded-full ${draft.status === "NEW" ? "bg-blue-500" : draft.status === "CREATED" ? "bg-lime-500" : "bg-red-500"}`}
                />
                {draft.status === "NEW"
                  ? "New"
                  : draft.status === "CREATED"
                    ? "Created"
                    : "Deleted"}
              </div>
            </div>
            <hr className="mt-4 mb-8" />
            <div className="flex justify-center">
              <div className="w-full sm:w-2/3 md:w-[60%] h-56">
                {draft.status === "NEW" ? (
                  <EditSlider
                    entity={placeRequest}
                    setEntity={setPlaceRequest}
                  />
                ) : (
                  <ViewSlider images={placeRequest.photos} height={56} />
                )}
              </div>
            </div>
            <form onSubmit={handleSubmit} id="create-draft-place-form">
              <div className="flex flex-col gap-3 mt-8">
                <div className="relative">
                  <label className="block text-[10px] text-gray-700">
                    Search tags
                  </label>
                  <SearchTags
                    value={placeSearchTags}
                    setValue={setPlaceSearchTags}
                  />
                </div>
                <div className="relative">
                  <label className="block text-[10px] text-gray-700">
                    Place tags
                  </label>
                  <Select
                    required
                    value={placeRequest.tagIds}
                    onChange={(value) =>
                      setPlaceRequest((prevEntity) => ({
                        ...prevEntity,
                        tagIds: value,
                      }))
                    }
                    className="rounded-full text-xs"
                    placeholder="Select place tags"
                    isMulti={true}
                    options={tags.map((type) => ({
                      value: type.id,
                      label: type.name,
                    }))}
                  ></Select>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
                  <div className="flex flex-col gap-3">
                    <PlaceGeneralInfo
                      place={placeRequest}
                      onChange={setPlaceRequest}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <PlaceContacts
                      contacts={placeRequest.contacts}
                      onChange={handeGeneralContactsChange}
                    />
                  </div>
                </div>
                <LocationUpdate
                  entity={placeRequest}
                  setEntity={setPlaceRequest}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="col-span-5 xl:col-span-2 ">
          <div className="flex flex-col gap-8">
            <div className="rounded-md shadow-md bg-white p-8">
              <DraftReviews
                draftReview={{
                  comment: draft.review,
                  rating: draft.rating,
                  createdBy: draft.createdBy,
                  createdAt: draft.createdAt,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DraftDetails;
