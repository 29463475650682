import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { ImBlocked } from "react-icons/im";
import { FaExclamationTriangle, FaStar } from "react-icons/fa";
import Avatar from "../../components/Images/Avatar";
import { useAuthHeader } from "react-auth-kit";
import { PiHandsClappingLight } from "react-icons/pi";
import ViewSlider from "../../components/Slider/ViewSlider";
import { toast } from "react-toastify";

function UserReviews({ userId }) {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const { data, loading } = useFetch(
    `/v1/admin/users/${userId}/reviews?page=${page}&pageSize=3&sortBy=createdAt&order=DESC`,
  );
  const [userReviews, setUserReviews] = useState([]);

  useEffect(() => {
    if (data) {
      setUserReviews((prevReviews) => [...prevReviews, ...data.data]);
    }
  }, [data]);

  const handleCommentBlock = async (review) => {
    setIsSubmitting(true);
    try {
      const action = review.isBlocked ? "unblock" : "block";
      await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/admin/reviews/${review.id}/${action}`,
        {
          method: "POST",
          headers: {
            Authorization: authHeaderValue,
          },
        },
      );
      review.isBlocked = !review.isBlocked;
      setUserReviews(
        userReviews &&
          userReviews.map((c) => (c.id === review.id ? review : c)),
      );
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    !loading && (
      <div className="flex flex-col">
        <div className=" mb-4 text-center">
          <span className="font-semibold text-sm">
            REVIEWS: ({data.pageMetadata.totalElements})
          </span>
        </div>
        {userReviews.map((review, index) => (
          <div key={index} className="flex flex-col my-4 gap-3">
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <Avatar image={review.createdBy.photoUrl} />
                <h5 className="text-sm flex items-center gap-2">
                  {review.createdBy.firstName} {review.createdBy.lastName}
                  <span className="text-[10px] text-gray-600">
                    ({new Date(review.createdAt).toLocaleDateString()})
                  </span>
                </h5>
              </div>
              <div className="flex items-center gap-6 text-sm">
                <div className="flex gap-2 items-center">
                  <div
                    className={`p-1 rounded-full ${review.isBlocked ? "bg-red-600" : "bg-green-500"}`}
                  />
                  {review.isBlocked ? "Blocked" : "Active"}
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-col w-full items-center">
              <div className="text-start w-full break-words">
                <span className="text-xs">{review.comment}</span>
              </div>
              {review.photos.length !== 0 && (
                <div className="flex w-full xl:w-full  md:w-1/2 h-44 m-4">
                  <ViewSlider images={review.photos} height={44} />
                </div>
              )}
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-4">
                <div className="text-xs flex gap-1 items-center">
                  <FaStar className="text-yellow-500" />
                  {review.rating}
                </div>
                <div className="text-xs flex gap-1 items-center">
                  <PiHandsClappingLight />
                  {review.likedBy.length}
                </div>
              </div>
              <div className="flex gap-2">
                {review.reports.length !== 0 && (
                  <div className="flex gap-1 items-center text-xs">
                    <FaExclamationTriangle className="text-red-600" />
                    {review.reports.length}
                  </div>
                )}
                <button
                  onClick={() => handleCommentBlock(review)}
                  disabled={isSubmitting}
                  className={`flex gap-2 items-center p-0.5 px-2 ${review.isBlocked ? "border bg-white text-black hover:bg-gray-900 hover:text-white" : "text-white bg-gray-900 hover:bg-white hover:text-black"} border  duration-300 rounded-md text-[10px] `}
                >
                  <ImBlocked />
                  {review.isBlocked ? "UNBLOCK" : "BLOCK"}
                </button>
              </div>
            </div>
          </div>
        ))}
        {userReviews.length === 0 && (
          <div className="text-center text-xs text-gray-600">
            There is no any reviews...
          </div>
        )}
        {!data.pageMetadata.last && (
          <div
            onClick={() => setPage(page + 1)}
            className="w-full item-center my-2 justify-center text-center hover:text-gray-700 hover:cursor-pointer"
          >
            <span className="font-semibold text-sm">SHOW MORE</span>
          </div>
        )}
      </div>
    )
  );
}

export default UserReviews;
