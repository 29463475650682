import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { FaStar } from "react-icons/fa";
import PaginationControl from "../../components/Table/PaginationControl";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Avatar from "../../components/Images/Avatar";
import { IoIosArrowDown } from "react-icons/io";

const Drafts = () => {
  const navigate = useNavigate();
  const [searchSetting, setSearchSetting] = useState({
    page: 0,
    size: 10,
    sortBy: "createdAt",
    sortDirection: "DESC",
    status: "NEW",
  });
  const { data: draftResponse, loading } = useFetch(
    `/v1/admin/places/draft?page=${searchSetting.page}&pageSize=${searchSetting.size}&status=${searchSetting.status}&sortBy=${searchSetting.sortBy}&order=${searchSetting.sortDirection}`,
  );

  const handleSearchSettingsChange = (event) => {
    const { name, value } = event.target;
    setSearchSetting((prevEntity) => ({
      ...prevEntity,
      [name]: value,
      page: 0,
    }));
  };

  return (
    <div>
      <div className="flex justify-between mb-6 items-center">
        <span className="font-bold text-lg">DRAFTS</span>
      </div>
      <div className="bg-white p-6 rounded-md shadow-md">
        <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-3 gap-6 mb-6">
          <div className="relative">
            <label className="block text-[10px] text-gray-700">
              Sort by
              <select
                id="model"
                name="sortBy"
                defaultValue="createdAt"
                onChange={handleSearchSettingsChange}
                className="custom-select"
              >
                <option value="createdAt">Recently created</option>
                <option value="updatedAt">Recently updated</option>
              </select>
              <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
            </label>
          </div>
          <div className="relative">
            <label className="block text-[10px] text-gray-700">
              Order by
              <select
                name="sortDirection"
                defaultValue="DESC"
                onChange={handleSearchSettingsChange}
                className="custom-select"
              >
                <option value="DESC">Descending</option>
                <option value="ASC">Ascending</option>
              </select>
              <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
            </label>
          </div>
          <div className="relative">
            <label className="block text-[10px] text-gray-700">
              Status
              <select
                name="status"
                defaultValue="NEW"
                onChange={handleSearchSettingsChange}
                className="custom-select"
              >
                <option value="NEW">New</option>
                <option value="CREATED">Created</option>
                <option value="DISMISSED">Dismissed</option>
              </select>
              <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
            </label>
          </div>
        </div>
        {loading ? (
          <span>Loading...</span>
        ) : (
          <div className="mb-6">
            <table className="w-full">
              <thead className="text-gray-700 text-xs bg-gray-100 ">
                <tr>
                  <TableHeader name="ID" />
                  <TableHeader name="NAME" />
                  <TableHeader name="CREATED BY" />
                  <TableHeader name="STATUS" />
                  <TableHeader name="COUNTRY" />
                  <TableHeader name="CITY" />
                  <TableHeader name="RATING" />
                </tr>
              </thead>
              <tbody>
                {draftResponse.data.map((draft, i) => (
                  <tr
                    key={i}
                    onClick={() =>
                      navigate(`/drafts/${draft.id}`, { state: { draft } })
                    }
                    className="border-b text-xs hover:bg-gray-50 duration-300 hover:cursor-pointer"
                  >
                    <TableRow value={draft.id} />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <Avatar image={draft.photos[0]} />
                          <div>{draft.name}</div>
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <Avatar image={draft.createdBy.photoUrl} />
                          <div>
                            {draft.createdBy.firstName}{" "}
                            {draft.createdBy.lastName}
                          </div>
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <span
                            className={`p-1 rounded-full ${
                              draft.status === "NEW"
                                ? "bg-blue-500"
                                : draft.status === "CREATED"
                                  ? "bg-lime-500"
                                  : "bg-red-500"
                            }`}
                          ></span>
                          {draft.status}
                        </div>
                      }
                    />
                    <TableRow value={draft.country} />
                    <TableRow value={draft.city} />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          {[...Array(5)].map((_, index) => (
                            <span
                              key={index}
                              className={`${draft.rating > index ? "text-yellow-500" : "text-gray-300"}`}
                            >
                              <FaStar />
                            </span>
                          ))}
                        </div>
                      }
                    />
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationControl
              searchSetting={searchSetting}
              setSearchSetting={setSearchSetting}
              paginationSettings={draftResponse.pageMetadata}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Drafts;
