import React from "react";
import useFetch from "../../hooks/useFetch";

function SearchTags({ placeId }) {
  const { data: searchTags, loading } = useFetch(
    `/v1/admin/places/${placeId}/searchTags`,
  );

  return loading ? (
    <span>Loading...</span>
  ) : (
    <>
      {searchTags.map((searchTag, index) => (
        <div
          key={index}
          className="flex gap-1 items-center p-1 rounded-md shadow-sm text-xs"
        >
          <div className={`text-gray-600`}>#</div>
          <span className="text-[10px]">{searchTag.name}</span>
        </div>
      ))}
    </>
  );
}

export default SearchTags;
