import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";

function PlaceMap({ lat, lon, zoom = 18, onPointerChange = undefined }) {
  useEffect(() => {}, [lat, lon]);

  const handleClick = (e) => {
    if (onPointerChange !== undefined) {
      onPointerChange(e);
    }
  };

  const MapClickEvents = () => {
    useMapEvents({
      click: handleClick,
    });
  };

  const markerIcon = new L.Icon({
    iconUrl: require("../../images/pointer.png"),
    iconSize: [44, 44],
    iconAnchor: [22, 44],
    popupAnchor: [0, -38],
  });

  return (
    <div className="w-full h-full">
      <MapContainer
        center={[lat, lon]}
        zoom={zoom}
        style={{
          height: "100%",
          width: "100%",
          background: "content-box",
          cursor: "auto",
        }}
        scrollWheelZoom={true}
      >
        <MapClickEvents />
        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[lat, lon]} icon={markerIcon}></Marker>
      </MapContainer>
    </div>
  );
}

export default PlaceMap;
