import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import { updatePromo } from "./promoApi";
import { toast } from "react-toastify";
import PromoEditSection from "../../components/Promo/PromoEditSection";
import PromoActionHeader from "../../components/Promo/PromoActionHeader";

const EditPromo = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <div className="max-w-xl mx-auto">
      <PromoActionHeader loading={isSubmitting} isNew={false} />
      <PromoEditForm setIsSubmitting={setIsSubmitting} />
    </div>
  );
};

const PromoEditForm = ({ setIsSubmitting }) => {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const navigate = useNavigate();
  const location = useLocation();
  const promo = location.state ? location.state.promo : null;
  const [promoRequest, setPromoRequest] = useState();

  useEffect(() => {
    if (promo) {
      setPromoRequest({
        id: promo.id,
        title: promo.title,
        text: promo.text,
        link: promo.link,
        image: promo.image,
        isVisible: promo.isVisible,
      });
    }
  }, [promo]);

  const handlePromoCreate = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    updatePromo(promoRequest, authHeaderValue)
      .then(() => {
        toast.success("Promo has been updated");
        navigate(`/promos/${promo.id}`);
      })
      .catch((error) => toast.error(error))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="bg-white p-8 rounded-md shadow-md">
      <form id="promo-create-form" onSubmit={handlePromoCreate}>
        <PromoEditSection promo={promoRequest} setPromo={setPromoRequest} />
      </form>
    </div>
  );
};

export default EditPromo;
