import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { AiTwotoneEdit } from "react-icons/ai";
import Avatar from "../../components/Images/Avatar";
import Claims from "../../components/Claims/Claims";
import { useAuthHeader } from "react-auth-kit";
import useFetch from "../../hooks/useFetch";
import { FaStar } from "react-icons/fa";
import PlaceReviews from "./PlaceReviews";
import { ImBlocked } from "react-icons/im";
import ViewSlider from "../../components/Slider/ViewSlider";
import Contacts from "../../components/Socials/Contacts";
import PlaceTags from "../../components/Tags/PlaceTags";
import SearchTags from "../../components/Tags/SearchTags";
import { toast } from "react-toastify";
import PlaceMap from "../../components/Map/PlaceMap";

function PlaceDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const [blocked, setBlocked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: place, loading: placeLoading } = useFetch(
    `/v1/places/${params.placeId}`,
  );

  useEffect(() => {
    if (place) {
      setBlocked(place.isBlocked);
    }
  }, [place]);

  const handlePlaceBlock = useCallback(
    async (isBlocked) => {
      setIsSubmitting(true);
      try {
        const actions = isBlocked ? "unblock" : "block";
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URI}/v1/admin/places/${place.id}/${actions}`,
          {
            method: "POST",
            headers: {
              Authorization: authHeaderValue,
              "Content-Type": "application/json",
            },
          },
        );
        if (response.ok) {
          setBlocked(!isBlocked);
        } else {
          toast.error("Couldn't block place: " + response.status);
        }
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setIsSubmitting(false);
      }
    },
    [authHeaderValue, place],
  );

  return (
    <>
      {placeLoading ? (
        <span>Loading...</span>
      ) : (
        <div className="max-w-6xl mx-auto">
          <div className="flex mb-6 justify-between items-center">
            <div className="flex items-center">
              <Link
                to={"/places"}
                className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
              >
                <IoChevronBackSharp />
                BACK
              </Link>
              <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
                <span>PLACE:</span>
                <span>{place.id}</span>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() => handlePlaceBlock(blocked)}
                disabled={isSubmitting}
                className={`block-btn`}
              >
                <ImBlocked />
                {blocked ? "UNBLOCK" : "BLOCK"}
              </button>
              <button
                onClick={() =>
                  navigate(`/places/${place.id}/edit`, {
                    state: { place: place },
                  })
                }
                disabled={isSubmitting}
                className={`edit-btn`}
              >
                <AiTwotoneEdit />
                EDIT
              </button>
            </div>
          </div>
          <div className=" grid grid-cols-5 gap-8">
            <div className="flex flex-col col-span-5 xl:col-span-3 gap-8">
              <div className="rounded-md shadow-md bg-white p-8">
                <div className="flex justify-between">
                  <Link
                    to={`/users/${place.createdBy.id}`}
                    className="flex items-center gap-4"
                  >
                    <Avatar image={place.createdBy.photoUrl} />
                    <div className="hidden text-left lg:block">
                      <div className="block text-sm font-medium text-black">
                        {place.createdBy.firstName} {place.createdBy.lastName}
                      </div>
                      <div className="block text-xs text-gray-600">
                        {new Date(place.createdAt).toLocaleDateString()}
                      </div>
                    </div>
                  </Link>
                  <div className="flex items-center gap-6 text-sm">
                    <div className="text-sm flex gap-1 items-center">
                      <FaStar className="text-yellow-500" />
                      {place.rating.toFixed(1)}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        className={`p-1 rounded-full ${blocked ? "bg-yellow-500" : "bg-lime-500"}`}
                      />
                      {blocked ? "Blocked" : "Active"}
                    </div>
                  </div>
                </div>
                <hr className="mt-4 mb-8" />
                <div className="flex flex-col max-w-lg mx-auto justify-center">
                  <div className="flex justify-center">
                    <div className="w-full h-72">
                      <ViewSlider images={place.photos} />
                    </div>
                  </div>
                  <div className="flex flex-col gap-5 mt-10">
                    <div className=" mt-2 text-center">
                      <div className="font-semibold text-xl text-gray-700">
                        {place.name}
                      </div>
                      <div className="flex flex-col text-center text-xs">
                        <span className="text-gray-700 font-semibold">
                          {place.country} ({place.city})
                        </span>
                        <span className="text-[10px]">{place.address}</span>
                      </div>
                    </div>
                    <div className="flex relative gap-2 justify-center">
                      <Contacts contacts={place.contacts} />
                    </div>
                    <div className="flex flex-wrap justify-center gap-x-4 gap-y-2">
                      <PlaceTags tags={place.placeTags} />
                    </div>
                    <div className="flex flex-wrap justify-center gap-x-4 gap-y-2">
                      <SearchTags placeId={place.id} />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col text-end gap-1 text-xs font-semibold">
                        <span>Category:</span>
                        <span>Type:</span>
                        <span>Price:</span>
                      </div>
                      <div className="flex flex-col text-start gap-1 text-xs">
                        <span>{place.placeCategory.name}</span>
                        <span>{place.type ? place.type : "Not provided"}</span>
                        <span>
                          {place.price
                            ? Array(place.price).fill("$").join("")
                            : "Not provided"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {place.businessOwner && (
                  <div className="mt-4">
                    <hr />
                    <Link
                      to={`/users/${place.createdBy.id}`}
                      className="flex items-center gap-4 mt-4"
                    >
                      <Avatar image={place.createdBy.photoUrl} />
                      <div className="hidden text-left lg:block">
                        <div className="block text-sm font-medium text-black">
                          {place.createdBy.firstName} {place.createdBy.lastName}
                        </div>
                        <div className="block text-xs text-gray-600">
                          Business owner
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              <div className="rounded-md shadow-md bg-white p-4">
                <div className="w-full h-72">
                  <PlaceMap lat={place.lat} lon={place.lon} />
                </div>
              </div>
              <div className="rounded-md shadow-md bg-white p-8">
                <Claims reports={place.reports} type="place" />
              </div>
            </div>
            <div className="col-span-5 xl:col-span-2">
              <div className="flex flex-col gap-8">
                <div className="rounded-md shadow-md bg-white p-8 overflow-y-auto max-h-xs">
                  <PlaceReviews placeId={place.id} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlaceDetails;
