import { toast } from "react-toastify";

export const validatePlaceBeforeCreate = (placeRequest) => {
  if (placeRequest.photos.length === 0) {
    throw new Error("Place must have at least one photo!");
  }
  if (
    !Object.keys(placeRequest.contacts).some(
      (key) => placeRequest.contacts[key] !== "",
    )
  ) {
    throw new Error("Place must have at least one contact!");
  }
};

export const addSearchTagsToPlace = async (
  placeId,
  placeSearchTags,
  authHeaderValue,
) => {
  let searchTagsIds = placeSearchTags
    .filter((searchTag) => !searchTag.isNew)
    .map((searchTag) => searchTag.value);
  const newSearchNames = placeSearchTags
    .filter((searchTag) => searchTag.isNew)
    .map((searchTag) => searchTag.label);
  if (newSearchNames.length !== 0) {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/places/searchTags`,
      {
        method: "POST",
        body: JSON.stringify({ searchTagNames: newSearchNames }),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.ok) {
      const jsonData = await response.json();
      searchTagsIds = searchTagsIds.concat(jsonData.map((tag) => tag.id));
    } else {
      toast.error("Couldn't create new search tags");
    }
  }
  const tagsResponse = await fetch(
    `${process.env.REACT_APP_SERVER_URI}/v1/admin/places/${placeId}/searchTags`,
    {
      method: "POST",
      body: JSON.stringify({ searchTagIds: searchTagsIds }),
      headers: {
        Authorization: authHeaderValue,
        "Content-Type": "application/json",
      },
    },
  );
  if (!tagsResponse.ok) {
    toast.error("Couldn't set search tags for place");
  }
};
