import {useEffect, useRef} from "react";

/**
 * This hook provide an effect that reacts to all state changes except the initial page render.
 * Therefore you can use it to track all the stat updates
 */
const useUpdate = (effect, dependencies) => {
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }

        return effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};

export default useUpdate;
