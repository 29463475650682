import React, { useState } from "react";
import { useSignIn } from "react-auth-kit";
import jwt from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";

function Login() {
  const [showError, setShowError] = useState(false);
  const signIn = useSignIn();

  const loginWithGoogle = async ({ credential }) => {
    try {
      const jwtResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/auth/admin/login/google/headless`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id_token: credential }),
        },
      );
      const jwtResponseData = await jwtResponse.json();

      const userResponse = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/users/me`,
        {
          headers: { Authorization: `Bearer ${jwtResponseData.accessToken}` },
        },
      );
      const userResponseData = await userResponse.json();

      const tokenInfo = jwt(jwtResponseData.accessToken);
      const currentDate = Math.floor(Date.now() / 1000);

      signIn({
        token: jwtResponseData.accessToken,
        expiresIn: Math.floor((tokenInfo.exp - currentDate) / 60),
        tokenType: "Bearer",
        authState: {
          username: userResponseData.email,
          picture: userResponseData.photoUrl,
          id: userResponseData.id,
        },
      });
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <section className="bg-gray-100 h-screen">
      <div className="flex flex-col items-center justify-content-center mx-auto">
        <div className="w-full text-center rounded-lg shadow-lg mt-12 max-w-md md:p-4 bg-white border border-gray-200">
          <div className="p-6 space-y-6">
            <div className="flex flex-col justify-center items-center my-6">
              <img
                className="w-16 h-16 mb-8"
                src={require("../../images/logo.png")}
                alt="logo"
              />
              <span className="text-black text-3xl font-bold ">
                DOVKOLO CLUB
              </span>
            </div>
            <p className="font-sans">SIGN IN</p>
            <div className="flex flex-col items-center w-full py-6 md:py-8 bg-white shadow-lg rounded-xl border border-gray-100">
              <GoogleLogin
                onSuccess={loginWithGoogle}
                width={290}
                theme="filled_black"
                text="signin_with"
                shape="pill"
                size="large"
                logo_alignment="center"
                onError={() => setShowError(true)}
              />
            </div>
            <div className="mb-6 text-center">
              {showError && (
                <span className="text-red-900">
                  You don't have permission to access!
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
