import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { ImBlocked } from "react-icons/im";
import { useAuthHeader } from "react-auth-kit";
import useFetch from "../../hooks/useFetch";
import RequestsComments from "./RequestsComments";
import Claims from "../../components/Claims/Claims";
import Avatar from "../../components/Images/Avatar";
import { AiFillDelete } from "react-icons/ai";

function RequestDetails() {
  const params = useParams();
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const { data: request, loading } = useFetch(
    `/v1/admin/requests/posts/${params.requestId}`,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (request) {
      setBlocked(request.isBlocked);
      setDeleted(request.isDeleted);
    }
  }, [request]);

  const handleRequestBlock = useCallback(
    async (requestBlocked) => {
      setIsSubmitting(true);
      try {
        const action = requestBlocked ? "unblock" : "block";
        await fetch(
          `${process.env.REACT_APP_SERVER_URI}/v1/admin/requests/posts/${request.id}/${action}`,
          {
            method: "POST",
            headers: {
              Authorization: authHeaderValue,
            },
          },
        );
        setBlocked(!requestBlocked);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [authHeaderValue, request],
  );

  const handleRequestDelete = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/requests/posts/${request.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: authHeaderValue,
          },
        },
      );
      setDeleted(true);
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsSubmitting(false);
    }
  }, [authHeaderValue, request]);

  return loading ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-6xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={"/requests"}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>REQUEST:</span>
            <span>{request.id}</span>
          </div>
        </div>
        {!deleted && (
          <div className="flex items-center gap-4">
            <button
              onClick={() => handleRequestBlock(blocked)}
              disabled={isSubmitting}
              className={`block-btn`}
            >
              <ImBlocked />
              {blocked ? "UNBLOCK" : "BLOCK"}
            </button>
            <button
              onClick={() => handleRequestDelete()}
              disabled={isSubmitting}
              className={`delete-btn`}
            >
              <AiFillDelete />
              DELETE
            </button>
          </div>
        )}
      </div>
      <div className=" grid grid-cols-5 gap-8">
        <div className="flex flex-col col-span-5 xl:col-span-3 gap-8">
          <div className="rounded-md shadow-md bg-white p-8">
            <div className="flex justify-between">
              <Link
                to={`/users/${request.createdBy.id}`}
                className="flex items-center gap-4"
              >
                <Avatar image={request.createdBy.photoUrl} />
                <div className="hidden text-left lg:block">
                  <div className="block text-sm font-medium text-black">
                    {request.createdBy.firstName} {request.createdBy.lastName}
                  </div>
                  <div className="block text-xs text-gray-600">
                    {new Date(request.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </Link>
              <div className="flex items-center gap-2 text-sm">
                <div
                  className={`p-1 rounded-full ${deleted ? "bg-red-500" : blocked ? "bg-yellow-500" : "bg-green-500"}`}
                />
                {deleted ? "Deleted" : blocked ? "Blocked" : "Active"}
              </div>
            </div>
            <hr className="mt-4" />
            <div className="grid grid-cols-5 gap-4 mt-5">
              <span className="col-span-1 text-gray-700 font-semibold text-sm">
                Title:
              </span>
              <span className="col-span-4 text-sm break-words">
                {request.title}
              </span>
              <span className="col-span-1 text-gray-700 font-semibold text-sm">
                Message:
              </span>
              <span className="col-span-4 text-sm break-words">
                {request.message}
              </span>
            </div>
          </div>
          <div className="rounded-md shadow-md bg-white p-8">
            <Claims reports={request.reports} type="requests" />
          </div>
        </div>
        <div className="col-span-5 xl:col-span-2">
          <div className="rounded-md shadow-md bg-white p-8 overflow-y-auto max-h-2xl">
            <RequestsComments requestId={request.id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestDetails;
