import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { createOption } from "../SearchTags/SearchTags";
import Select from "react-select";

function Countries({ value, setValue }) {
  const { data, loading } = useFetch(`/v2/countries`);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data) {
      setOptions(
        data.map((country) => createOption(country.name, country.code)),
      );
    }
  }, [data]);

  return (
    <Select
      className="rounded-full text-xs"
      placeholder="Select or input country"
      isMulti={false}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 2000 }),
      }}
      isDisabled={loading}
      isLoading={loading}
      onChange={(newValue) => setValue(newValue)}
      options={options}
      value={value}
      required
    />
  );
}

export default Countries;
