import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import useFetch from "../../hooks/useFetch";
import { IoChevronBackSharp } from "react-icons/io5";
import { AiOutlineEye, AiTwotoneEdit } from "react-icons/ai";
import { MdPlace } from "react-icons/md";
import CollectionPlaces from "./CollectionPlaces";
import PlaceMap from "../../components/Map/PlaceMap";

function CollectionDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const { data: collection, loading } = useFetch(
    `/v1/admin/places/collections/${params.collectionId}`,
  );
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (collection) {
      setIsDeleted(collection.isDeleted);
    }
  }, [collection]);

  const handleCollectionDelete = useCallback(async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/admin/places/collections/${collection.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: authHeaderValue,
          },
        },
      );
      setIsDeleted(true);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [authHeaderValue, collection]);

  return loading ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-6xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={"/collections"}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>COLLECTION:</span>
            <span>{collection.id}</span>
          </div>
        </div>
        {!isDeleted && (
          <div className="flex items-center gap-4">
            <button
              onClick={() => handleCollectionDelete(isDeleted)}
              className={"delete-btn"}
            >
              <AiOutlineEye />
              DELETE
            </button>
            <button
              onClick={() =>
                navigate(`/collections/${collection.id}/edit`, {
                  state: { collection: collection },
                })
              }
              className={`edit-btn`}
            >
              <AiTwotoneEdit />
              EDIT
            </button>
          </div>
        )}
      </div>
      <div className=" grid grid-cols-5 gap-8">
        <div className="flex flex-col col-span-5 xl:col-span-2 gap-8">
          <div className="bg-white p-8 pb-12 rounded-md shadow-md flex-col">
            <div className="flex justify-between items-center">
              <div className="flex gap-4">
                <div className="text-xs flex gap-1 items-center">
                  <MdPlace />
                  {collection.placeCount}
                </div>
                <div className="flex items-center gap-2  text-sm">
                  <div
                    className={`p-1 rounded-full ${collection.isSystem ? "bg-blue-500" : "bg-green-500"}`}
                  />
                  {collection.isSystem ? "System" : "User"}
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-2  text-sm">
                  <div
                    className={`p-1 rounded-full ${collection.isPublic ? "bg-green-500" : "bg-orange-500"}`}
                  />
                  {collection.isPublic ? "Public" : "Private"}
                </div>
                <div className="flex items-center gap-2  text-sm">
                  <div
                    className={`p-1 rounded-full ${isDeleted ? "bg-red-500" : "bg-green-500"}`}
                  />
                  {isDeleted ? "Deleted" : "Active"}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mt-8">
              <div className="w-full md:w-1/2 xl:w-full  h-52 border rounded-lg">
                <div
                  style={{ backgroundImage: `url(${collection.image})` }}
                  className="w-full h-full relative rounded-lg bg-center bg-cover"
                />
              </div>
              <div className="flex flex-col text-center font-semibold text-xl text-gray-700 mt-8">
                {collection.name}
                {collection.countryCode && (
                  <span className="text-[10px]">
                    {collection.country} ({collection.countryCode})
                  </span>
                )}
                <span className="text-[10px]">
                  ({new Date(collection.createdAt).toLocaleDateString()})
                </span>
              </div>
            </div>
          </div>
          <div className="rounded-md shadow-md bg-white p-4">
            <div className="w-full h-72">
              <PlaceMap lat={collection.lat} lon={collection.lon} zoom={12} />
            </div>
          </div>
        </div>
        <div className="col-span-5 xl:col-span-3">
          <div className="flex flex-col gap-8">
            <div className="rounded-md shadow-md bg-white p-8 overflow-y-auto max-h-xs">
              <CollectionPlaces collectionId={collection.id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionDetails;
