import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Avatar from "../../components/Images/Avatar";
import PaginationControl from "../../components/Table/PaginationControl";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";

function Articles() {
  const navigate = useNavigate();
  const [searchSetting, setSearchSetting] = useState({
    page: 0,
    size: 10,
    countryCode: "",
  });
  const { data, loading } = useFetch(
    `/v1/admin/articles?page=${searchSetting.page}&pageSize=${searchSetting.size}&countryCode=${searchSetting.countryCode}`,
  );
  const { data: countries, loading: countryLoading } = useFetch(
    `/v2/countries/articles?pageSize=100`,
  );

  return (
    <div>
      <div className="flex justify-between mb-6 items-center">
        <span className="font-bold text-lg">ARTICLES</span>
        <Link to={"/articles/create"} className={`submit-btn`}>
          <AiOutlinePlus />
          CREATE
        </Link>
      </div>
      <div className="bg-white p-6 rounded-md shadow-md">
        {loading || countryLoading ? (
          <span>Loading...</span>
        ) : (
          <div className="mb-6">
            <div className="grid justify-start grid-cols-1 xl:grid-cols-5 lg:grid-cols-3 gap-2 lg:gap-6 mb-6">
              <div className="relative">
                <label className="relative text-[10px] text-gray-700">
                  Country
                  <select
                    name="role"
                    defaultValue=""
                    onChange={(event) => {
                      setSearchSetting((prevState) => ({
                        ...prevState,
                        countryCode: event.target.value,
                      }));
                    }}
                    className="custom-select"
                  >
                    <option value="">All</option>
                    {countries.data.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
                </label>
              </div>
            </div>
            <table className="w-full">
              <thead className="text-gray-700 text-xs bg-gray-100 ">
                <tr>
                  <TableHeader name="ID" />
                  <TableHeader name="TITLE" />
                  <TableHeader name="PRIVACY" />
                  <TableHeader name="VISIBILITY" />
                  <TableHeader name="COUNTRY" />
                  <TableHeader name="LIKES" />
                  <TableHeader name="CREATED AT" />
                </tr>
              </thead>
              <tbody>
                {data.data.map((article, i) => (
                  <tr
                    key={i}
                    onClick={() => navigate(`/articles/${article.id}`)}
                    className="border-b text-xs hover:bg-gray-50 duration-300 hover:cursor-pointer"
                  >
                    <TableRow value={article.id} />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <Avatar image={article.image} />
                          <div>{article.title}</div>
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <div
                            className={`p-1 rounded-full ${article.isPrivate ? "bg-orange-500" : "bg-green-500"}`}
                          />
                          {article.isPrivate ? "Private" : "Public"}
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <div
                            className={`p-1 rounded-full ${article.isVisible ? "bg-green-500" : "bg-orange-500"}`}
                          />
                          {article.isVisible ? "Visible" : "Hidden"}
                        </div>
                      }
                    />
                    <TableRow value={article.country} />
                    <TableRow value={article.likes} />
                    <TableRow
                      value={new Date(article.createdAt).toLocaleDateString()}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationControl
              searchSetting={searchSetting}
              setSearchSetting={setSearchSetting}
              paginationSettings={data.pageMetadata}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Articles;
