import React from "react";
import Avatar from "../../components/Images/Avatar";
import { Link } from "react-router-dom";

const UserBusinessPlaces = ({ places }) => {
  return (
    <div className="flex flex-col text-center gap-4">
      <span className="font-semibold text-sm">
        BUSINESS PLACES: ({places.length})
      </span>
      {places.map((place) => (
        <Link
          to={`/places/${place.id}`}
          className="flex items-center gap-4 mt-4"
        >
          <Avatar image={place.photo} />
          <div className="hidden text-left lg:block">
            <div className="block text-sm font-medium text-black">
              {place.name}
            </div>
            <div className="block text-xs text-gray-600">Business place</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default UserBusinessPlaces;
