import React from "react";
import { MdAddAPhoto } from "react-icons/md";
import Select from "react-select";
import useFetch from "../../hooks/useFetch";
import Countries from "../Countries/Countries";

function CollectionEditSection({ collection, setCollection }) {
  const { data: placeIds, loading } = useFetch(`/v1/admin/places/ids`);

  const handleCountryUpdate = (country) => {
    setCollection((prevEntity) => ({ ...prevEntity, countryCode: country }));
  };

  const handleCollectionFieldChange = (event) => {
    const { name, value } = event.target;
    setCollection((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  return !collection || loading ? (
    <span>Loading...</span>
  ) : (
    <div className="flex flex-col justify-center items-center">
      <div className="w-2/3 h-52 border rounded-lg">
        {collection.image ? (
          <img
            src={collection.image}
            alt="imageUrl"
            className="w-full h-full object-cover rounded-lg"
          />
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-md bg-gray-50">
            <div className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50">
              <MdAddAPhoto size="32" className="text-gray-600" />
            </div>
          </div>
        )}
      </div>
      <div className="w-full mt-2">
        <div className="flex flex-col gap-4">
          <label className="relative text-[10px] text-gray-700">
            Image
            <input
              name="image"
              onChange={handleCollectionFieldChange}
              defaultValue={collection.image}
              className="custom-input"
              placeholder="Collection image"
              required
            />
          </label>
          <div>
            <label className="relative text-[10px] text-gray-700">
              Collection name
              <input
                name="name"
                onChange={handleCollectionFieldChange}
                defaultValue={collection.name}
                placeholder="Collection name"
                className="custom-input"
                required
              />
            </label>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 w-full">
            <div className="flex items-center justify-center col-span-2">
              <label className="w-full items-center justify-center relative text-[10px] text-gray-700">
                Country
                <Countries
                  value={collection.countryCode}
                  setValue={handleCountryUpdate}
                />
              </label>
            </div>
            <div className="flex gap-2 items-center w-full justify-center pt-5 col-span-1">
              <input
                onChange={() => {
                  setCollection((prevState) => ({
                    ...prevState,
                    isPublic: !collection.isPublic,
                  }));
                }}
                checked={collection.isPublic}
                name="isPublic"
                type="checkbox"
                className="custom-checkbox"
              />
              <span className="text-sm">Public</span>
            </div>
          </div>
          <div className="relative mt-2">
            <label className="block text-[10px] text-gray-700">
              Collection places
            </label>
            <Select
              required
              isClearable={false}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 2000 }),
              }}
              value={collection.placeIds}
              onChange={(value) =>
                setCollection((prevEntity) => ({
                  ...prevEntity,
                  placeIds: value,
                }))
              }
              className="rounded-full text-xs"
              placeholder="Select collection places"
              isMulti={true}
              options={placeIds.map((placeId) => ({
                value: placeId,
                label: placeId,
              }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionEditSection;
