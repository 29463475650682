import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "react-auth-kit";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider
    authType={"cookie"}
    cookieDomain={window.location.hostname}
    authName={"_auth"}
    cookieSecure={false}
  >
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    <GoogleOAuthProvider clientId="82045032002-l3spsmbqf1cpppmfk88sslc1go1pm5mr.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </AuthProvider>,
);
