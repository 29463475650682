import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { ImBlocked } from "react-icons/im";
import { FaExclamationTriangle } from "react-icons/fa";
import Avatar from "../../components/Images/Avatar";
import { useAuthHeader } from "react-auth-kit";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function RequestsComments({ requestId }) {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const { data, loading } = useFetch(
    `/v1/admin/requests/posts/${requestId}/comments?page=${page}&pageSize=5`,
  );
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (data) {
      setComments((prevComments) => [...prevComments, ...data.data]);
    }
  }, [data]);

  const handleCommentBlock = async (comment) => {
    setIsSubmitting(true);
    try {
      const action = comment.isBlocked ? "unblock" : "block";
      await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/admin/requests/comments/${comment.id}/${action}`,
        {
          method: "POST",
          headers: {
            Authorization: authHeaderValue,
          },
        },
      );
      comment.isBlocked = !comment.isBlocked;
      setComments(
        comments && comments.map((c) => (c.id === comment.id ? comment : c)),
      );
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    !loading && (
      <div className="flex flex-col">
        <div className="w-full item-center mb-4 justify-center text-center">
          <span className="font-semibold text-sm">
            ANSWERS: ({data.pageMetadata.totalElements})
          </span>
        </div>
        {comments.map((comment, index) => (
          <div key={index} className="flex flex-col my-4 gap-3">
            <div className="flex justify-between">
              <Link
                to={`/users/${comment.createdBy.id}`}
                className="flex items-center gap-2"
              >
                <Avatar image={comment.createdBy.photoUrl} />
                <h5 className="text-sm flex items-center gap-2">
                  {comment.createdBy.firstName} {comment.createdBy.lastName}
                  <span className="text-[10px] text-gray-600">
                    ({new Date(comment.createdAt).toLocaleDateString()})
                  </span>
                </h5>
              </Link>
              <div className="flex items-center gap-6 text-sm">
                <div className="flex gap-2 items-center">
                  <div
                    className={`p-1 rounded-full ${comment.isBlocked ? "bg-red-600" : "bg-green-500"}`}
                  />
                  {comment.isBlocked ? "Blocked" : "Active"}
                </div>
              </div>
            </div>
            <hr />
            <p>
              <span className="text-xs break-words">{comment.comment}</span>
            </p>
            <div className="flex justify-end items-center gap-2">
              {comment.reports.length !== 0 && (
                <div className="flex gap-1 items-center text-xs">
                  <FaExclamationTriangle className="text-red-600" />
                  {comment.reports.length}
                </div>
              )}
              <button
                onClick={() => handleCommentBlock(comment)}
                disabled={isSubmitting}
                className={`flex gap-2 items-center p-0.5 px-2 ${comment.isBlocked ? "border bg-white text-black hover:bg-gray-900 hover:text-white" : "text-white bg-gray-900 hover:bg-white hover:text-black"} border  duration-300 rounded-md text-[10px] `}
              >
                <ImBlocked />
                {comment.isBlocked ? "UNBLOCK" : "BLOCK"}
              </button>
            </div>
          </div>
        ))}
        {comments.length === 0 && (
          <div className="text-center text-xs text-gray-600">
            There is no any answers...
          </div>
        )}
        {!data.pageMetadata.last && (
          <div
            onClick={() => setPage(page + 1)}
            className="w-full item-center my-2 justify-center text-center hover:text-gray-700 hover:cursor-pointer"
          >
            <span className="font-semibold text-sm">SHOW MORE</span>
          </div>
        )}
      </div>
    )
  );
}

export default RequestsComments;
