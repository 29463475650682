import {useCallback} from "react";

function useLocalStorage(key, initialValue) {

    const getItem = useCallback(() => {
        const item = localStorage.getItem(key);
        return (item || item !== '0') ? JSON.parse(item) : initialValue;
    }, [key, initialValue]);

    const setItem = useCallback((data) => {
        localStorage.setItem(key, JSON.stringify(data));
    }, [key]);

    const removeItem = useCallback(() => {
        localStorage.removeItem(key)
    }, [key]);

    return { getItem, setItem, removeItem };
}

export default useLocalStorage;
