import React from "react";
import { IoWarningOutline } from "react-icons/io5";

function ConfirmationModal({ title, content, onSubmit, onCancel }) {
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 text-center items-center p-4">
          <div className="relative transform overflow-hidden rounded-lg w-full bg-white shadow-xl transition-all mx-auto my-8 max-w-md p-4">
            <div className="bg-white py-2">
              <div className="flex flex-col justify-center items-center">
                <div className="flex text-lime-600 flex-shrink-0 items-center justify-center rounded-full bg-lime-100 h-12 w-12">
                  <IoWarningOutline className="h-8 w-8" />
                </div>
                <div className="mt-2 text-center">
                  <h3
                    className="text-sm my-2 font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                  <div className="mt-4">{content}</div>
                </div>
              </div>
            </div>
            {(onCancel || onSubmit) && (
              <div className="grid text-sm grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-4">
                {onCancel && (
                  <button
                    type="button"
                    onClick={onCancel}
                    className="p-1 hover:border-gray-400 duration-300 border rounded-md w-full"
                  >
                    Cancel
                  </button>
                )}
                {onSubmit && (
                  <button
                    type="button"
                    onClick={onSubmit}
                    className="p-1 bg-lime-500 hover:bg-lime-600 duration-300 text-white rounded-md w-full"
                  >
                    Confirm
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
