import React from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";

export const ProtectedLayout = () => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated() ? (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-gray-100">
        <Topbar />
        <main className="">
          <div className="mx-auto max-w-screen-2xl p-4 md:p-8 2xl:p-10">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  ) : (
    <Navigate to={"/login"} />
  );
};
