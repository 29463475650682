import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import PaginationControl from "../../components/Table/PaginationControl";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Avatar from "../../components/Images/Avatar";
import { FaExclamationTriangle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";

const Requests = () => {
  const navigate = useNavigate();
  const [searchSetting, setSearchSetting] = useState({
    page: 0,
    size: 10,
    sortBy: "createdAt",
    sortDirection: "DESC",
    status: "active",
    onlyWithReports: false,
  });
  const { data, loading } = useFetch(
    `/v1/admin/requests/posts?page=${searchSetting.page}&pageSize=${searchSetting.size}&sortBy=${searchSetting.sortBy}&order=${searchSetting.sortDirection}&status=${searchSetting.status}&onlyWithReports=${searchSetting.onlyWithReports}`,
  );

  const handleSearchSettingsChange = (event) => {
    const { name, value } = event.target;
    setSearchSetting((prevEntity) => ({
      ...prevEntity,
      [name]: value,
      page: 0,
    }));
  };

  return (
    <div>
      <div className="flex justify-between mb-6 items-center">
        <span className="font-bold text-lg">REQUESTS</span>
      </div>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <div className="bg-white p-6 rounded-md shadow-md">
          <div className="grid justify-start grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 gap-x-8 gap-2 mb-6">
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Status
                <select
                  name="status"
                  defaultValue="active"
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="blocked">Blocked</option>
                  <option value="deleted">Deleted</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Sort by
                <select
                  id="model"
                  name="sortBy"
                  defaultValue="createdAt"
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="createdAt">Recently created</option>
                  <option value="updatedAt">Recently updated</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Order by
                <select
                  name="sortDirection"
                  defaultValue="DESC"
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="DESC">Descending</option>
                  <option value="ASC">Ascending</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="items-center flex gap-4 md:gap-6 pt-5">
              <div className="flex gap-2 items-center">
                <input
                  onChange={() =>
                    setSearchSetting({
                      ...searchSetting,
                      onlyWithReports: !searchSetting.onlyWithReports,
                    })
                  }
                  checked={searchSetting.onlyWithReports}
                  type="checkbox"
                  className="custom-checkbox"
                />
                <span className="text-sm">Only with claims</span>
              </div>
            </div>
          </div>
          <div className="mb-6">
            <table className="w-full">
              <thead className="text-gray-700 text-xs bg-gray-100 ">
                <tr>
                  <TableHeader name="ID" />
                  <TableHeader name="TITLE" />
                  <TableHeader name="CREATED BY" />
                  <TableHeader name="STATUS" />
                  <TableHeader name="CLAIMS" />
                  <TableHeader name="COMMENTS" />
                  <TableHeader name="CREATED AT" />
                </tr>
              </thead>
              <tbody>
                {data.data.map((request, i) => (
                  <tr
                    key={i}
                    onClick={() => navigate(`/requests/${request.id}`)}
                    className="border-b text-xs hover:bg-gray-50 duration-300 hover:cursor-pointer"
                  >
                    <TableRow value={request.id} />
                    <TableRow value={request.title} />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <Avatar image={request.createdBy.photoUrl} />
                          <div>
                            {request.createdBy.firstName}{" "}
                            {request.createdBy.lastName}
                          </div>
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <span
                            className={`p-1 rounded-full ${request.isDeleted ? "bg-red-500" : request.isBlocked ? "bg-orange-500" : "bg-green-500"}`}
                          />
                          {request.isDeleted
                            ? "Deleted"
                            : request.isBlocked
                              ? "Blocked"
                              : "Active"}
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        request.reports.length !== 0 && (
                          <div className="flex gap-1 items-center">
                            <FaExclamationTriangle className="text-red-600" />
                            {request.reports.length}
                          </div>
                        )
                      }
                    />
                    <TableRow value={request.commentsCount} />
                    <TableRow
                      value={new Date(request.createdAt).toLocaleDateString()}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationControl
              searchSetting={searchSetting}
              setSearchSetting={setSearchSetting}
              paginationSettings={data.pageMetadata}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Requests;
