import React, { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { createPromo } from "./promoApi";
import { toast } from "react-toastify";
import PromoEditSection from "../../components/Promo/PromoEditSection";
import PromoActionHeader from "../../components/Promo/PromoActionHeader";

const CreatePromo = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <div className="max-w-xl mx-auto">
      <PromoActionHeader loading={isSubmitting} isNew={true} />
      <PromoCreateForm setIsSubmitting={setIsSubmitting} />
    </div>
  );
};

const PromoCreateForm = ({ setIsSubmitting }) => {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const navigate = useNavigate();
  const [promo, setPromo] = useState({
    title: "",
    text: "",
    link: "",
    image: "",
    isVisible: true,
  });

  const handlePromoCreate = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    createPromo(promo, authHeaderValue)
      .then((id) => {
        toast.success("Promo has been created");
        navigate(`/promos/${id}`);
      })
      .catch((error) => toast.error(error))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="bg-white p-8 rounded-md shadow-md">
      <form id="promo-create-form" onSubmit={handlePromoCreate}>
        <PromoEditSection promo={promo} setPromo={setPromo} />
      </form>
    </div>
  );
};

export default CreatePromo;
