import React, { useState } from "react";
import useFetch from "../../hooks/useFetch";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Avatar from "../../components/Images/Avatar";
import PaginationControl from "../../components/Table/PaginationControl";
import { useNavigate } from "react-router-dom";

function CollectionPlaces({ collectionId }) {
  const navigate = useNavigate();
  const [searchSetting, setSearchSetting] = useState({ page: 0, size: 10 });
  const { data: places, loading } = useFetch(
    `/v1/admin/places/collections/${collectionId}/places?page=${searchSetting.page}&pageSize=${searchSetting.size}`,
  );

  return loading ? (
    <span>Loading...</span>
  ) : (
    <>
      <div className=" mb-4 text-center">
        <span className="font-semibold text-sm">
          PLACES: ({places.pageMetadata.totalElements})
        </span>
      </div>
      <div className="mb-6">
        <table className="w-full">
          <thead className="text-gray-700 text-xs bg-gray-100 ">
            <tr>
              <TableHeader name="ID" />
              <TableHeader name="NAME" />
              <TableHeader name="STATUS" />
            </tr>
          </thead>
          <tbody>
            {places.data.map((place, i) => (
              <tr
                key={i}
                onClick={() => navigate(`/places/${place.id}`)}
                className="border-b text-xs hover:bg-gray-50 duration-300 hover:cursor-pointer"
              >
                <TableRow value={place.id} />
                <TableRow
                  value={
                    <div className="flex items-center gap-2">
                      <Avatar image={place.photos[0]} />
                      <div>{place.name}</div>
                    </div>
                  }
                />
                <TableRow
                  value={
                    <div className="flex items-center gap-2">
                      <span
                        className={`p-1 rounded-full ${place.isBlocked ? "bg-yellow-500" : "bg-lime-500"}`}
                      />
                      {place.isBlocked ? "Blocked" : "Active"}
                    </div>
                  }
                />
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationControl
          searchSetting={searchSetting}
          setSearchSetting={setSearchSetting}
          paginationSettings={places.pageMetadata}
        />
      </div>
    </>
  );
}

export default CollectionPlaces;
