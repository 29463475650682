export const createPromo = async (promo, authHeaderValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/promos`,
      {
        method: "POST",
        body: JSON.stringify(promo),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error(`Request failed with status ${response.status}`);
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't create promo");
  }
};

export const updatePromo = async (promo, authHeaderValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/promos/${promo.id}`,
      {
        method: "PUT",
        body: JSON.stringify(promo),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error(`Request failed with status ${response.status}`);
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't update promo");
  }
};

export const setVisible = async (promoId, authHeaderValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/promos/${promoId}/visible`,
      {
        method: "POST",
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't set promo visible");
  }
};

export const setInvisible = async (promoId, authHeaderValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/v1/admin/promos/${promoId}/invisible`,
      {
        method: "POST",
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      },
    );
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't set promo hidden");
  }
};
