import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdManageAccounts } from "react-icons/md";
import { ImExit } from "react-icons/im";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { Link } from "react-router-dom";
import Avatar from "../Images/Avatar";

function Topbar() {
  const logout = useSignOut();
  const userState = useAuthUser();
  const menuRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <header className="top-header" ref={menuRef}>
      <div className="flex flex-grow px-4 py-3 justify-end items-center">
        <div className="relative">
          <div
            className="flex items-center gap-4 cursor-pointer"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <span className="hidden text-right lg:block">
              <span className="block text-sm font-medium text-black">
                {userState()?.username}
              </span>
              <span className="block text-xs text-gray-600">Admin</span>
            </span>
            <div>
              <Avatar image={userState().picture} />
            </div>
            <IoIosArrowDown
              className={`${showDropdown && "rotate-180"} duration-300`}
            />
          </div>
          {showDropdown && (
            <div className="absolute right-0 mt-3.5 flex w-56 flex-col rounded-sm border border-stroke bg-white shadow-sm">
              <ul className="flex flex-col gap- border-b border-stroke px-6 py-4">
                <li>
                  <Link
                    to={`/users/${userState().id}`}
                    onClick={() => setShowDropdown(false)}
                    className="flex items-center gap-3.5 text-base duration-300 ease-in-out hover:text-lime-800 cursor-pointer"
                  >
                    <MdManageAccounts />
                    My profile
                  </Link>
                </li>
              </ul>
              <button
                onClick={() => logout()}
                className="flex items-center gap-3.5 py-4 px-6 duration-300 ease-in-out hover:text-red-800"
              >
                <ImExit />
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Topbar;
