import { useEffect } from "react";

function useDebouncedSearchInput(searchInput, setSearchSetting, name) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchSetting((prevSettings) => ({
        ...prevSettings,
        [name]: searchInput,
      }));
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchInput, setSearchSetting, name]);
}

export default useDebouncedSearchInput;
