import React, { useEffect, useState } from "react";

import CreatableSelect from "react-select/creatable";
import useFetch from "../../hooks/useFetch";

export const createOption = (label, id, isNew = false) => ({
  label,
  value: isNew ? label.toLowerCase().replace(/\W/g, "") : id,
  isNew,
});

function SearchTags({ value, setValue }) {
  const { data, loading } = useFetch(`/v1/admin/places/searchTags`);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data) {
      setOptions(
        data.map((searchTag) => createOption(searchTag.name, searchTag.id)),
      );
    }
  }, [data]);

  const handleCreate = (inputValue) => {
    if (inputValue.trim() !== "") {
      const newOptions = inputValue.split(/\s+/).filter(Boolean);
      const newOptionObjects = newOptions.map((newOption) =>
        createOption(newOption, newOption, true),
      );
      setOptions((prevOptions) => [...prevOptions, ...newOptionObjects]);
      setValue((prevValue) => [...prevValue, ...newOptionObjects]);
    }
  };

  return (
    <CreatableSelect
      className="rounded-full text-xs"
      placeholder="Select or create search tags"
      isMulti
      isDisabled={loading}
      isLoading={loading}
      onChange={(newValue) => setValue(newValue)}
      onCreateOption={handleCreate}
      options={options}
      value={value}
    />
  );
}

export default SearchTags;
