import React from "react";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const DayValueChart = ({ data, name }) => {
  const chartData = {
    labels: data.map((item) => {
      const date = new Date(item.date);
      return `${date.toLocaleDateString("en-US", { month: "numeric", day: "numeric" })}`;
    }),
    datasets: [
      {
        label: " Count",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgb(163, 230, 53)",
        borderColor: "rgb(132, 204, 22)",
        borderWidth: 2,
        data: data.map((item) => item.count),
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      <div className="font-bold text-sm mb-4">{name}</div>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default DayValueChart;
