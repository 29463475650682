import React from "react";
import { HiOutlinePhotograph } from "react-icons/hi";

function Avatar({ image }) {
  return (
    <>
      {image ? (
        <div className="flex h-10 w-10">
          <div
            style={{ backgroundImage: `url(${image})` }}
            className="w-full h-full relative rounded-full bg-center bg-cover border border-gray-400"
          ></div>
        </div>
      ) : (
        <div
          className={`flex items-center justify-center h-10 w-10 ${!image && "bg-gray-200 rounded-full"}`}
        >
          <HiOutlinePhotograph className="w-2/3 h-2/3" />
        </div>
      )}
    </>
  );
}

export default Avatar;
