import React, { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoChevronBackSharp } from "react-icons/io5";
import { BsSaveFill } from "react-icons/bs";
import { createOption } from "../../components/SearchTags/SearchTags";
import ArticleEditSection from "../../components/Article/ArticleEditSection";

function EditArticle() {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const location = useLocation();
  const navigate = useNavigate();
  const article = location.state ? location.state.article : null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [articleRequest, setArticleRequest] = useState();

  useEffect(() => {
    if (article) {
      setArticleRequest({
        id: article.id,
        title: article.title,
        text: article.text,
        image: article.image,
        countryCode: createOption(article.country, article.countryCode),
        isVisible: article.isVisible,
        isPrivate: article.isPrivate,
      });
    }
  }, [article]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/admin/articles/${articleRequest.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            ...articleRequest,
            countryCode: articleRequest.countryCode.value,
          }),
          headers: {
            Authorization: authHeaderValue,
            "Content-Type": "application/json",
          },
        },
      );
      if (response.ok) {
        const responseData = await response.json();
        toast.success("Article has been updated");
        navigate(`/articles/${responseData.id}`);
      } else {
        toast.error("Couldn't update article: " + response.status);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  return !article ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={"/articles"}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>ARTICLE:</span>
            <span>{article.id}</span>
          </div>
        </div>
        <div className="flex items-center">
          <button
            form="article-update-form"
            type="submit"
            disabled={isSubmitting}
            className={`edit-btn`}
          >
            <BsSaveFill />
            SAVE
          </button>
        </div>
      </div>
      <div className="bg-white p-8 pb-12 rounded-md shadow-md">
        <form id="article-update-form" onSubmit={handleSubmit}>
          <ArticleEditSection
            article={articleRequest}
            setArticle={setArticleRequest}
          />
        </form>
      </div>
    </div>
  );
}

export default EditArticle;
