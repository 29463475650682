import React from "react";

function PaginationControl({
  paginationSettings,
  searchSetting,
  setSearchSetting,
}) {
  const handlePageSizeChange = (event) => {
    setSearchSetting({ ...searchSetting, size: event.target.value, page: 0 });
  };

  const handleNextPageClick = () => {
    if (!paginationSettings.last) {
      setSearchSetting({ ...searchSetting, page: searchSetting.page + 1 });
    }
  };

  const handlePreviousPageClick = () => {
    if (!paginationSettings.first) {
      setSearchSetting({ ...searchSetting, page: searchSetting.page - 1 });
    }
  };

  return (
    <nav
      className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-y-4 justify-center items-center"
      aria-label="Table navigation"
    >
      <div className="w-full md:text-start">
        <div className="flex justify-center items-center md:justify-start">
          <span className="text-xs mr-3">Elements per page:</span>
          <select
            value={searchSetting.size}
            onChange={handlePageSizeChange}
            className="block p-2 pl-2 text-xs text-black border border-gray-300 hover:cursor-pointer rounded-md focus:outline-none"
          >
            {[10, 20, 50].map((value, i) => (
              <option key={i} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="text-xs mr-3">Total elements:</div>
        <div className="text-xs font-semibold">
          {paginationSettings.totalElements}
        </div>
      </div>
      <div className="w-full md:text-end">
        <div className="flex justify-center md:justify-end items-center">
          <div className="flex text-xs font-normal mr-4">
            Page:
            <span className="mx-1.5">{searchSetting.page + 1} </span>
            of
            <span className="ml-1.5">
              {" "}
              {Math.ceil(paginationSettings.totalElements / searchSetting.size)}
            </span>
          </div>
          <ul className="inline-flex -space-x-px text-xs h-8">
            <li>
              <button
                disabled={paginationSettings.first}
                onClick={handlePreviousPageClick}
                className="w-24 sm:w-28 flex items-center justify-center py-2 border border-gray-300 duration-300 rounded-l-lg hover:bg-lime-200 hover:text-black"
              >
                Prev
              </button>
            </li>
            <li>
              <button
                disabled={paginationSettings.last}
                onClick={handleNextPageClick}
                className="w-24 sm:w-28 flex items-center justify-center py-2 border border-gray-300 duration-300 rounded-r-lg hover:bg-lime-200 hover:text-black"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default PaginationControl;
