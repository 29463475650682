import React from "react";
import { GrFormNext } from "react-icons/gr";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="absolute flex z-10 top-0 right-1 h-full items-center">
      <div
        className="bg-white opacity-60 text-white flex p-1.5 hover:cursor-pointer rounded-full hover:opacity-75"
        onClick={onClick}
      >
        <GrFormNext size={24} />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="absolute flex z-10 top-0 left-1 h-full items-center">
      <div
        className="bg-white opacity-60 text-white flex p-1.5 hover:cursor-pointer rounded-full hover:opacity-75"
        onClick={onClick}
      >
        <GrFormNext className="rotate-180" size={24} />
      </div>
    </div>
  );
}

const sliderSettings = {
  dots: true,
  className: "relative rounded-lg border hidden h-full w-full",
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

function CustomSlider({ children }) {
  return <Slider {...sliderSettings}>{children}</Slider>;
}

export default CustomSlider;
