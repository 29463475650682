import React from "react";
import { MdAddAPhoto } from "react-icons/md";
import Countries from "../Countries/Countries";

function ArticleEditSection({ article, setArticle }) {
  const handleCountryUpdate = (country) => {
    setArticle((prevEntity) => ({ ...prevEntity, countryCode: country }));
  };

  const handleArticleFieldChange = (event) => {
    const { name, value } = event.target;
    setArticle((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  return !article ? (
    <span>Loading...</span>
  ) : (
    <div className="flex flex-col justify-center items-center">
      <div className="w-2/3 h-52 border rounded-lg">
        {article.image ? (
          <img
            src={article.image}
            alt="imageUrl"
            className="w-full h-full object-cover rounded-lg"
          />
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-md bg-gray-50">
            <div className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50">
              <MdAddAPhoto size="32" className="text-gray-600" />
            </div>
          </div>
        )}
      </div>
      <div className="w-full mt-2">
        <div className="flex flex-col gap-4">
          <label className="relative text-[10px] text-gray-700">
            Image
            <input
              name="image"
              onChange={handleArticleFieldChange}
              defaultValue={article.image}
              className="custom-input"
              placeholder="Article image"
              required
            />
          </label>
          <div className="flex gap-6 justify-center mt-4">
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
              <div className="w-full items-center justify-center">
                <label className="relative text-[10px] text-gray-700">
                  Country
                  <Countries
                    value={article.countryCode}
                    setValue={handleCountryUpdate}
                  />
                </label>
              </div>
              <div className="flex gap-8 items-center w-full justify-center pt-5">
                <div className="flex gap-2 items-center">
                  <input
                    onChange={() => {
                      setArticle((prevState) => ({
                        ...prevState,
                        isVisible: !article.isVisible,
                      }));
                    }}
                    checked={article.isVisible}
                    name="isVisible"
                    type="checkbox"
                    className="custom-checkbox"
                  />
                  <span className="text-sm">Visible</span>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    onChange={() => {
                      setArticle((prevState) => ({
                        ...prevState,
                        isPrivate: !article.isPrivate,
                      }));
                    }}
                    checked={article.isPrivate}
                    name="isPrivate"
                    type="checkbox"
                    className="custom-checkbox"
                  />
                  <span className="text-sm">Private</span>
                </div>
              </div>
            </div>
          </div>
          <label className="relative text-[10px] text-gray-700">
            Title
            <input
              name="title"
              onChange={handleArticleFieldChange}
              defaultValue={article.title}
              placeholder="Article title"
              className="custom-input"
              required
            />
          </label>
          <label className="relative text-[10px] text-gray-700">
            Text
            <textarea
              name="text"
              onChange={handleArticleFieldChange}
              value={article.text}
              rows="8"
              className="custom-input"
              placeholder="Article content"
              required
            />
          </label>
        </div>
      </div>
    </div>
  );
}

export default ArticleEditSection;
