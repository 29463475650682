import React from "react";

function PlaceTags({ tags }) {
  return (
    <>
      {tags &&
        tags.map((tag, index) => (
          <div
            key={index}
            className="flex gap-2 items-center p-1 border rounded-md shadow-sm"
          >
            <div
              className={`p-1 ${tag.ukrainian ? "bg-sky-400" : "bg-gray-400"} rounded-full`}
            ></div>
            <span className="text-[10px]">{tag.name}</span>
          </div>
        ))}
    </>
  );
}

export default PlaceTags;
