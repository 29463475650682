import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMdNotifications } from "react-icons/io";
import { MdPlace } from "react-icons/md";
import { SiDatabricks } from "react-icons/si";
import { HiUsers } from "react-icons/hi2";
import { FaAngleRight, FaGift } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { BsFillFileRichtextFill } from "react-icons/bs";
import { HiCollection } from "react-icons/hi";

function Sidebar() {
  const location = useLocation();
  const isSideBarOpen = localStorage.getItem("sidebarOpen");
  const [open, setOpen] = useState(
    isSideBarOpen ? isSideBarOpen === "true" : true,
  );
  const [activeIndex, setActiveIndex] = useState(-1);

  const menuItems = useMemo(
    () => [
      { title: "Dashboard", icon: <VscGraph size="20" />, path: "/dashboard" },
      { title: "Drafts", icon: <SiDatabricks size="20" />, path: "/drafts" },
      { title: "Places", icon: <MdPlace size="20" />, path: "/places" },
      {
        title: "Collections",
        icon: <HiCollection size="20" />,
        path: "/collections",
      },
      { title: "Users", icon: <HiUsers size="20" />, path: "/users" },
      {
        title: "Requests",
        icon: <BiMessageRoundedDetail size="20" />,
        path: "/requests",
      },
      { title: "Promos", icon: <FaGift size="20" />, path: "/promos" },
      {
        title: "Articles",
        icon: <BsFillFileRichtextFill size="20" />,
        path: "/articles",
      },
      {
        title: "Notifications",
        icon: <IoMdNotifications size="20" />,
        path: "/notifications",
      },
    ],
    [],
  );

  useEffect(() => {
    const currentPath = location.pathname;
    const menuItemIndex = menuItems.findIndex((item) =>
      currentPath.includes(item.path),
    );
    if (menuItemIndex !== -1) {
      setActiveIndex(menuItemIndex);
    }
  }, [location.pathname, menuItems]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    localStorage.setItem("sidebarOpen", open);
  }, [open]);

  const toggleSidebar = () => {
    const newOpenState = !open;
    setOpen(newOpenState);
    localStorage.setItem("sidebarOpen", newOpenState);
  };

  return (
    <div
      className={`relative top-0 left-0 h-screen ${open ? "w-64 md:w-64" : "w-16"} bg-gray-900 duration-200 flex flex-col items-start p-2`}
    >
      <FaAngleRight
        className={`sidebar-arrow ${open && "rotate-180"}`}
        onClick={() => toggleSidebar()}
      />
      <SideBarLogo open={open} />
      <Divider />
      {menuItems.map((item, index) => (
        <SideBraIcon
          onClick={() => handleItemClick(index)}
          key={index}
          title={item.title}
          icon={item.icon}
          open={open}
          path={item.path}
          selected={index === activeIndex}
        />
      ))}
    </div>
  );
}

const SideBarLogo = ({ open }) => (
  <Link to={"/dashboard"}>
    <div className="flex items-center justify-between">
      <div
        className={`sidebar-logo group duration-200 ${open && "rotate-[360deg]"}`}
      >
        <img src={require("../../images/logo.png")} alt="logo" width={30} />
      </div>
      <div
        className={`flex ${!open && "hidden"} text-lg origin-left ease-out duration-300`}
      >
        <span className={`text-white ml-4 font-bold ${!open && "scale-0"}`}>
          DOVKOLO
        </span>
        <span className={`text-white ml-2 font-bold ${!open && "scale-0"}`}>
          CLUB
        </span>
      </div>
    </div>
  </Link>
);

const SideBraIcon = ({ selected, icon, title, open, path }) => (
  <Link to={path}>
    <div
      className={`sidebar-icon group ${selected ? "bg-lime-600 rounded-xl" : ""}`}
    >
      {icon}
      <span
        className={`sidebar-tooltip ${selected && "text-lime-500"} ${open ? "scale-100 left-10 min-w-[174px]" : "group-hover:scale-100 text-white"}`}
      >
        {title}
      </span>
      {selected && (
        <span
          className={`absolute -left-2 h-8 rounded-r-xl w-0.5 bg-lime-500 cursor-pointer`}
        ></span>
      )}
    </div>
  </Link>
);

const Divider = () => <hr className="sidebar-hr" />;

export default Sidebar;
