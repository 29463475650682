import React from "react";
import { MdNoPhotography } from "react-icons/md";

function ViewImageModal({ image, onCancel }) {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 items-center p-4">
          <div className="relative transform overflow-hidden rounded-lg w-full bg-white shadow-xl transition-all mx-auto my-8 max-w-md p-4">
            <div className="bg-white">
              <div className="flex flex-col justify-center">
                <div className="flex flex-col gap-4 mt-2 w-full">
                  <div className="flex justify-center w-full">
                    <div className="flex w-full h-96 bg-gray-300 rounded-lg">
                      {image ? (
                        <div
                          style={{ backgroundImage: `url(${image})` }}
                          className="w-full h-full relative rounded-lg bg-center bg-cover"
                        ></div>
                      ) : (
                        <div className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-md bg-gray-50">
                          <div className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50">
                            <MdNoPhotography
                              size="32"
                              className="text-gray-600"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex text-sm w-full mt-4">
              {onCancel && (
                <button
                  type="button"
                  onClick={onCancel}
                  className="p-1 bg-lime-600 text-white duration-300 border rounded-md w-full"
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewImageModal;
