import React, { useState } from "react";
import { BsFillSendFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { IoIosArrowDown } from "react-icons/io";
import useFetch from "../../hooks/useFetch";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useAuthHeader } from "react-auth-kit";
import dayjs from "dayjs";

function createDate(days) {
  let result = new Date();
  result.setDate(result.getDate() + days);
  return result;
}

const initNotification = {
  title: "",
  message: "",
  entityType: "",
  entityId: "",
  link: "",
  expiresAt: createDate(5).toISOString().split("T")[0],
};

function Notifications() {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const { data: entityTypes, loading } = useFetch(
    `/v1/admin/notifications/entityTypes`,
  );
  const [notification, setNotification] = useState(initNotification);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleSentNotification = async () => {
    setIsSubmitting(true);
    try {
      const url = `${process.env.REACT_APP_SERVER_URI}/v1/admin/notifications`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          ...notification,
          expiresAt: dayjs(notification.expiresAt).toISOString(),
        }),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        setShowModal(false);
        setNotification(initNotification);
        toast.success("Notification has been sent!");
      } else {
        setShowModal(false);
        toast.error("Couldn't send notification!");
      }
    } catch (error) {
      setShowModal(false);
      toast.success("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNotificationFiledChange = (event) => {
    const { name, value } = event.target;
    if (name === "entityType") {
      setNotification((prevEntity) => ({
        ...prevEntity,
        [name]: value,
        entityId: "",
        link: "",
      }));
    } else {
      setNotification((prevEntity) => ({ ...prevEntity, [name]: value }));
    }
  };

  return (
    <>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <div className="max-w-2xl mx-auto">
          <div className="flex justify-between mb-6 items-center">
            <span className="font-bold text-lg">NOTIFICATIONS</span>
            <button
              type="submit"
              form="send-notification-form"
              disabled={isSubmitting}
              className={`submit-btn`}
            >
              <BsFillSendFill />
              SEND
            </button>
          </div>

          {showModal && (
            <NotificationModal
              notification={notification}
              setShowModal={setShowModal}
              handleSentNotification={handleSentNotification}
            />
          )}

          <div className="bg-white px-6 py-8 rounded-md shadow-md flex-col">
            <form id="send-notification-form" onSubmit={handleFormSubmit}>
              <div className="flex flex-col gap-5 w-full items-center">
                <div className="w-full">
                  <label className="block mb-2 text-xs">Title</label>
                  <input
                    type="text"
                    value={notification.title}
                    onChange={handleNotificationFiledChange}
                    name="title"
                    className="custom-input"
                    placeholder="Notification title"
                    required
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 text-xs">Message</label>
                  <textarea
                    name="message"
                    value={notification.message}
                    onChange={handleNotificationFiledChange}
                    rows="3"
                    className="custom-input"
                    placeholder="Notification message"
                    required
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 text-xs">Link</label>
                  <input
                    type="text"
                    value={notification.link}
                    onChange={handleNotificationFiledChange}
                    name="link"
                    className="custom-input"
                    placeholder="Notification link"
                    required={notification.entityType === "LINK"}
                    disabled={notification.entityType !== "LINK"}
                  />
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="relative">
                    <label className="relative text-[10px] text-gray-700">
                      Entity type
                      <select
                        name="entityType"
                        required
                        onChange={handleNotificationFiledChange}
                        value={notification.entityType}
                        className="custom-select"
                      >
                        <option value="" disabled>
                          Select type
                        </option>
                        {entityTypes.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                      <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
                    </label>
                  </div>
                  <div className="relative">
                    <label className="relative text-[10px] text-gray-700">
                      Entity id
                    </label>
                    <input
                      type="number"
                      name="entityId"
                      value={notification.entityId}
                      onChange={handleNotificationFiledChange}
                      required={
                        notification.entityType !== "APP_UPDATE" ||
                        notification.entityType !== "LINK"
                      }
                      disabled={
                        notification.entityType === "APP_UPDATE" ||
                        notification.entityType === "LINK"
                      }
                      min={1}
                      className="custom-select"
                      placeholder="Entity id"
                    />
                  </div>
                  <div className="relative">
                    <label className="relative text-[10px] text-gray-700">
                      Expires
                    </label>
                    <input
                      type="date"
                      onChange={handleNotificationFiledChange}
                      required
                      value={notification.expiresAt}
                      className="custom-select"
                      name="expiresAt"
                      placeholder="Notification expiration"
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

const NotificationModal = ({
  notification,
  handleSentNotification,
  setShowModal,
}) => (
  <ConfirmationModal
    title="Are you sure you want to send this notification?"
    content={
      <div className="flex flex-col text-xs">
        <div className="grid grid-cols-2 gap-3">
          <div className="text-end font-semibold">Title:</div>
          <div className="text-start">{notification.title}</div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="text-end font-semibold">Message:</div>
          <div className="text-start break-words">{notification.message}</div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="text-end font-semibold">Entity type:</div>
          <div className="text-start">{notification.entityType}</div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="text-end font-semibold">Entity id:</div>
          <div className="text-start">{notification.entityId}</div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="text-end font-semibold">Link:</div>
          <div className="text-start break-words">{notification.link}</div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="text-end font-semibold">Expiration:</div>
          <div className="text-start">{notification.expiresAt}</div>
        </div>
      </div>
    }
    onCancel={() => setShowModal(false)}
    onSubmit={handleSentNotification}
  />
);

export default Notifications;
