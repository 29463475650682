import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomSlider from "./CustomSlider";
import ViewImageModal from "../Modal/ViewImageModal";
import { BiFullscreen } from "react-icons/bi";
import { MdNoPhotography } from "react-icons/md";

function ViewSlider({ images, height = 72 }) {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState();

  const handleShowModal = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  return (
    <>
      {showModal && (
        <ViewImageModal
          image={modalImage}
          onCancel={() => setShowModal(false)}
        />
      )}
      <CustomSlider>
        {images.map((imageUrl, index) => (
          <div key={index} className="group relative rounded-lg">
            <div
              style={{ backgroundImage: `url(${imageUrl})` }}
              className={`w-full h-${height} relative rounded-lg bg-center bg-cover origin-top duration-300`}
            ></div>
            <div className="flex flex-col items-center rounded-lg justify-center absolute w-full h-full scale-y-0 bottom-0 origin-top group-hover:scale-100 duration-100 p-8 bg-white bg-opacity-70">
              <div
                onClick={() => handleShowModal(imageUrl)}
                className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50 cursor-pointer"
              >
                <BiFullscreen size="32" className="text-gray-600" />
              </div>
            </div>
          </div>
        ))}
        {images.length === 0 && (
          <div className={`flex h-${height} bg-gray-300 rounded-lg`}>
            <div className="flex flex-col text-sm items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-md bg-gray-50">
              <div className="flex item-center justify-center flex-col p-6 bg-white rounded-full bg-opacity-50">
                <MdNoPhotography size="32" className="text-gray-600" />
              </div>
              <div className="text-sm mt-2">There is no any images...</div>
            </div>
          </div>
        )}
      </CustomSlider>
    </>
  );
}

export default ViewSlider;
