import React from "react";
import Avatar from "../../components/Images/Avatar";
import { FaStar } from "react-icons/fa";

function DraftReviews({ draftReview }) {
  return (
    <div className="flex flex-col">
      <div className="w-full item-center mb-4 justify-center text-center">
        <span className="font-semibold text-sm">AUTHOR REVIEW</span>
      </div>
      <div className="flex flex-col my-4 gap-3">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <Avatar image={draftReview.createdBy.photoUrl} />
            <h5 className="text-sm flex items-center gap-2">
              {draftReview.createdBy.firstName} {draftReview.createdBy.lastName}
              <span className="text-[10px] text-gray-600">
                ({new Date(draftReview.createdAt).toLocaleDateString()})
              </span>
            </h5>
          </div>
          <div className="flex items-center gap-6 text-sm">
            <div className="text-sm flex gap-1 items-center">
              <FaStar className="text-yellow-500" />
              {draftReview.rating}
            </div>
          </div>
        </div>
        <hr />
        <p>
          <span className="text-xs break-words">{draftReview.comment}</span>
        </p>
      </div>
    </div>
  );
}

export default DraftReviews;
