import React from "react";
import "./app.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./global.css";
import { LoginLayout } from "./layout/LoginLayout";
import { ProtectedLayout } from "./layout/ProtectedLayout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Drafts from "./pages/Drafts/Drafts";
import Requests from "./pages/Requests/Requests";
import Users from "./pages/Users/Users";
import Places from "./pages/Places/Places";
import UserDetails from "./pages/Users/UserDetails";
import Notifications from "./pages/Notifications/Notifications";
import RequestDetails from "./pages/Requests/RequestDetails";
import EditUser from "./pages/Users/EditUser";
import DraftDetails from "./pages/Drafts/DraftDetails";
import PlaceDetails from "./pages/Places/PlaceDetails";
import EditPlace from "./pages/Places/EditPlace";
import Articles from "./pages/Articles/Articles";
import ArticleDetails from "./pages/Articles/ArticleDetails";
import CreateArticle from "./pages/Articles/CreateArticle";
import EditArticle from "./pages/Articles/EditArticle";
import Collections from "./pages/Collections/Collections";
import CollectionDetails from "./pages/Collections/CollectionDetails";
import CreateCollection from "./pages/Collections/CreateCollection";
import EditCollection from "./pages/Collections/EditCollection";
import Promos from "./pages/Promos/Promos";
import CreatePromo from "./pages/Promos/CreatePromo";
import PromoDetails from "./pages/Promos/PromoDetails";
import EditPromo from "./pages/Promos/EditPromo";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/places" element={<Places />} />
          <Route path="/places/:placeId" element={<PlaceDetails />} />
          <Route path="/places/:placeId/edit" element={<EditPlace />} />
          <Route path="/collections" element={<Collections />} />
          <Route
            path="/collections/:collectionId"
            element={<CollectionDetails />}
          />
          <Route
            path="/collections/:collectionId/edit"
            element={<EditCollection />}
          />
          <Route path="/collections/create" element={<CreateCollection />} />
          <Route path="/drafts" element={<Drafts />} />
          <Route path="/drafts/:draftId" element={<DraftDetails />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:userId" element={<UserDetails />} />
          <Route path="/users/:userId/edit" element={<EditUser />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:articleId" element={<ArticleDetails />} />
          <Route path="/articles/:articleId/edit" element={<EditArticle />} />
          <Route path="/articles/create" element={<CreateArticle />} />
          <Route path="/requests/:requestId" element={<RequestDetails />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/promos" element={<Promos />} />
          <Route path="/promos/:promoId" element={<PromoDetails />} />
          <Route path="/promos/create" element={<CreatePromo />} />
          <Route path="/promos/:promoId/edit" element={<EditPromo />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
