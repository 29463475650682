import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { AiOutlinePlus } from "react-icons/ai";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Avatar from "../../components/Images/Avatar";
import PaginationControl from "../../components/Table/PaginationControl";
import { IoIosArrowDown } from "react-icons/io";

function Collections() {
  const navigate = useNavigate();
  const [searchSetting, setSearchSetting] = useState({
    page: 0,
    size: 10,
    isPublic: "",
    isDeleted: "",
    isSystem: "",
    countryCode: "",
  });
  const { data, loading } = useFetch(
    `/v1/admin/places/collections?page=${searchSetting.page}&pageSize=${searchSetting.size}&isPublic=${searchSetting.isPublic}&isDeleted=${searchSetting.isDeleted}&isSystem=${searchSetting.isSystem}&countryCode=${searchSetting.countryCode}`,
  );

  const { data: countries, loading: countryLoading } = useFetch(
    `/v2/countries/collections?pageSize=100`,
  );

  const handleSearchSettingsChange = (event) => {
    const { name, value } = event.target;
    setSearchSetting((prevEntity) => ({
      ...prevEntity,
      [name]: value,
      page: 0,
    }));
  };

  return (
    <div>
      <div className="flex justify-between mb-6 items-center">
        <span className="font-bold text-lg">COLLECTIONS</span>
        <Link to={"/collections/create"} className={`submit-btn`}>
          <AiOutlinePlus />
          CREATE
        </Link>
      </div>
      {loading || countryLoading ? (
        <span>Loading...</span>
      ) : (
        <div className="bg-white p-6 rounded-md shadow-md">
          <div className="grid justify-start grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 gap-x-8 gap-2 mb-6">
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Country
                <select
                  name="countries"
                  defaultValue=""
                  onChange={(event) => {
                    setSearchSetting((prevState) => ({
                      ...prevState,
                      countryCode: event.target.value,
                    }));
                  }}
                  className="custom-select"
                >
                  <option value="">All</option>
                  {countries.data.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Privacy
                <select
                  name="isPublic"
                  defaultValue=""
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="">All</option>
                  <option value="false">Private</option>
                  <option value="true">Public</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Owner
                <select
                  name="isSystem"
                  defaultValue=""
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="">All</option>
                  <option value="false">User</option>
                  <option value="true">System</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Status
                <select
                  name="isDeleted"
                  defaultValue="false"
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="">All</option>
                  <option value="false">Active</option>
                  <option value="true">Deleted</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
          </div>
          <div className="mb-6">
            <table className="w-full">
              <thead className="text-gray-700 text-xs bg-gray-100 ">
                <tr>
                  <TableHeader name="ID" />
                  <TableHeader name="NAME" />
                  <TableHeader name="PRIVACY" />
                  <TableHeader name="OWNER" />
                  <TableHeader name="STATUS" />
                  <TableHeader name="PLACE COUNT" />
                  <TableHeader name="COUNTRY" />
                  <TableHeader name="CREATED AT" />
                </tr>
              </thead>
              <tbody>
                {data.data.map((collection, i) => (
                  <tr
                    key={i}
                    onClick={() => navigate(`/collections/${collection.id}`)}
                    className="border-b text-xs hover:bg-gray-50 duration-300 hover:cursor-pointer"
                  >
                    <TableRow value={collection.id} />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <Avatar image={collection.image} />
                          <div>{collection.name}</div>
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <div
                            className={`p-1 rounded-full ${collection.isPublic ? "bg-green-500" : "bg-blue-500"}`}
                          />
                          {collection.isPublic ? "Public" : "Private"}
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <div
                            className={`p-1 rounded-full ${collection.isSystem ? "bg-green-500" : "bg-blue-500"}`}
                          />
                          {collection.isSystem ? "System" : "User"}
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <div
                            className={`p-1 rounded-full ${collection.isDeleted ? "bg-red-500" : "bg-green-500"}`}
                          />
                          {collection.isDeleted ? "Deleted" : "Active"}
                        </div>
                      }
                    />
                    <TableRow value={collection.placeCount} />
                    <TableRow value={collection.country} />
                    <TableRow
                      value={new Date(
                        collection.createdAt,
                      ).toLocaleDateString()}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationControl
              searchSetting={searchSetting}
              setSearchSetting={setSearchSetting}
              paginationSettings={data.pageMetadata}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Collections;
