import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import ProfileAvatar from "../../components/Images/ProfileAvatar";
import { BsSaveFill } from "react-icons/bs";
import useFetch from "../../hooks/useFetch";
import { toast } from "react-toastify";
import { useAuthHeader } from "react-auth-kit";
import Select from "react-select";
import { createOption } from "../../components/SearchTags/SearchTags";

function EditUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const user = location.state ? location.state.user : null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userRequest, setUserRequest] = useState();
  const { data: roles, loading } = useFetch(`/v1/admin/roles`);
  const { data: placeIds, loading: placesLoading } =
    useFetch(`/v1/admin/places/ids`);

  useEffect(() => {
    if (user) {
      setUserRequest({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        photoUrl: user.photoUrl,
        bio: user.bio,
        phone: user.phone,
        businessPlacesIds: user.businessPlaces.map((place) =>
          createOption(place.id, place.id),
        ),
        roleIds: user.roles.map((role) => createOption(role.name, role.id)),
      });
    }
  }, [user]);

  const handleUserUpdate = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const url = `${process.env.REACT_APP_SERVER_URI}/v1/admin/users/${userRequest.id}`;
      const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
          ...userRequest,
          businessPlacesIds: userRequest.businessPlacesIds.map(
            (place) => place.value,
          ),
          roleIds: userRequest.roleIds.map((role) => role.value),
        }),
        headers: {
          Authorization: authHeaderValue,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        await response.json();
        toast.success("User has been updated!");
        navigate(`/users/${userRequest.id}`);
      } else {
        toast.error("Couldn't update user: " + response.status);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUserProfileChange = (event) => {
    const { name, value } = event.target;
    if (name === "roleIds") {
      setUserRequest((prevEntity) => ({ ...prevEntity, [name]: [value] }));
    } else {
      setUserRequest((prevEntity) => ({ ...prevEntity, [name]: value }));
    }
  };

  return loading || placesLoading ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-2xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={`/users/${userRequest.id}`}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>USER:</span>
            <span>{userRequest.id}</span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button
            type="submit"
            form="update-user-form"
            disabled={isSubmitting}
            className={`submit-btn`}
          >
            <BsSaveFill />
            SAVE
          </button>
        </div>
      </div>
      <div className="bg-white p-8 rounded-md shadow-md flex-col py-16">
        <div className="flex flex-col justify-center items-center">
          <ProfileAvatar imageUrl={userRequest.photoUrl} />
          <div className="w-full mt-6">
            <form id="update-user-form" onSubmit={handleUserUpdate}>
              <div className="flex flex-col gap-4">
                <label className="relative text-[10px] text-gray-700">
                  User avatar
                  <input
                    name="photoUrl"
                    onChange={handleUserProfileChange}
                    defaultValue={userRequest.photoUrl}
                    className="custom-input"
                  />
                </label>

                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                  <label className="relative text-[10px] text-gray-700">
                    First name
                    <input
                      name="firstName"
                      onChange={handleUserProfileChange}
                      defaultValue={userRequest.firstName}
                      className="custom-input"
                    />
                  </label>
                  <label className="relative text-[10px] text-gray-700">
                    Last name
                    <input
                      name="lastName"
                      onChange={handleUserProfileChange}
                      defaultValue={userRequest.lastName}
                      className="custom-input"
                    />
                  </label>
                </div>
                <label className="relative text-[10px] text-gray-700">
                  User roles
                  <Select
                    required
                    value={userRequest.roleIds}
                    onChange={(value) =>
                      setUserRequest((prevEntity) => ({
                        ...prevEntity,
                        roleIds: value,
                      }))
                    }
                    className="rounded-full text-xs"
                    placeholder="Select place tags"
                    isMulti={true}
                    options={roles.map((role) => ({
                      value: role.id,
                      label: role.name,
                    }))}
                  ></Select>
                </label>
                <label className="relative text-[10px] text-gray-700">
                  About me
                  <textarea
                    name="bio"
                    onChange={handleUserProfileChange}
                    value={userRequest.bio}
                    rows="4"
                    className="custom-input"
                    placeholder="About me"
                  />
                </label>
              </div>
              <div className="relative mt-2">
                <label className="block text-[10px] text-gray-700">
                  Business places
                </label>
                <Select
                  isClearable={false}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 2000 }),
                  }}
                  value={userRequest.businessPlacesIds}
                  onChange={(value) =>
                    setUserRequest((prevEntity) => ({
                      ...prevEntity,
                      businessPlacesIds: value,
                    }))
                  }
                  className="rounded-full text-xs"
                  placeholder="Select business places"
                  isMulti={true}
                  options={placeIds.map((placeId) => ({
                    value: placeId,
                    label: placeId,
                  }))}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
