import React, { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoChevronBackSharp } from "react-icons/io5";
import { BsSaveFill } from "react-icons/bs";
import CollectionEditSection from "../../components/Collections/CollectionEditSection";
import { createOption } from "../../components/SearchTags/SearchTags";
import LocationUpdate from "../../components/Map/LocationUpdate";

function EditCollection() {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const location = useLocation();
  const navigate = useNavigate();
  const collection = location.state ? location.state.collection : null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [collectionRequest, setCollectionRequest] = useState();

  useEffect(() => {
    if (collection) {
      setCollectionRequest({
        id: collection.id,
        name: collection.name,
        image: collection.image,
        lat: collection.lat,
        lon: collection.lon,
        countryCode: createOption(collection.country, collection.countryCode),
        placeIds: collection.placeIds.map((placeId) =>
          createOption(placeId, placeId),
        ),
        isPublic: collection.isPublic,
      });
    }
  }, [collection]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/admin/places/collections/${collection.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            ...collectionRequest,
            countryCode: collectionRequest.countryCode.value,
            placeIds: collectionRequest.placeIds.map(
              (placeId) => placeId.value,
            ),
          }),
          headers: {
            Authorization: authHeaderValue,
            "Content-Type": "application/json",
          },
        },
      );
      if (response.ok) {
        const responseData = await response.json();
        toast.success("Collection has been updated");
        navigate(`/collections/${responseData.id}`);
      } else {
        toast.error("Couldn't update collection: " + response.status);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  return !collection ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={"/collections"}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>COLLECTION:</span>
            <span>{collection.id}</span>
          </div>
        </div>
        <div className="flex items-center">
          <button
            form="collection-update-form"
            type="submit"
            disabled={isSubmitting}
            className={`edit-btn`}
          >
            <BsSaveFill />
            SAVE
          </button>
        </div>
      </div>
      <div className="bg-white p-8 pb-12 rounded-md shadow-md">
        <form id="collection-update-form" onSubmit={handleSubmit}>
          <CollectionEditSection
            collection={collectionRequest}
            setCollection={setCollectionRequest}
          />
        </form>
        <LocationUpdate
          entity={collectionRequest}
          setEntity={setCollectionRequest}
        />
      </div>
    </div>
  );
}

export default EditCollection;
