import React, { useState } from "react";
import TableHeader from "../Table/TableHeader";
import TableRow from "../Table/TableRow";
import { useAuthHeader } from "react-auth-kit";

const typeUrls = {
  place: "/v1/admin/places/reports",
  review: "/v1/admin/reviews/reports",
  requests: "/v1/admin/requests/posts/reports",
  comments: "/v1/admin/requests/comments/reports",
  users: "/v1/admin/users/reports",
};

function Claims({ reports, type }) {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [claims, setClaims] = useState(reports);

  const handleClaimSolve = async (claim) => {
    setIsSubmitting(true);
    try {
      const reposnse = await fetch(
        `${process.env.REACT_APP_SERVER_URI}${typeUrls[type]}/${claim.id}/resolve`,
        {
          method: "POST",
          headers: {
            Authorization: authHeaderValue,
          },
        },
      );
      if (reposnse.ok) {
        claim.status = "RESOLVED";
        setClaims(claims.map((c) => (c.id === claim.id ? claim : c)));
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="w-full item-center mb-4 justify-center text-center">
        <span className="font-semibold text-sm">CLAIMS</span>
      </div>
      {claims.length === 0 ? (
        <div className="text-center mt-4 text-xs text-gray-600">
          There is no any claims...
        </div>
      ) : (
        <table className="w-full">
          <thead className="text-gray-700 text-xs bg-gray-100 ">
            <tr>
              <TableHeader name="TYPE" />
              <TableHeader name="COMMENT" />
              <TableHeader name="STATUS" />
              <TableHeader name="ACTION" />
            </tr>
          </thead>
          <tbody>
            {claims.map((claim, i) => (
              <tr key={i} className="border-b text-xs">
                <TableRow value={claim.reportType} />
                <TableRow value={claim.comment} />
                <TableRow
                  value={
                    <div className="flex items-center gap-2">
                      <div
                        className={`p-1 rounded-full ${claim.status === "PENDING" ? "bg-blue-500" : "bg-green-500"}`}
                      />
                      {claim.status === "PENDING" ? "Pending" : "Solved"}
                    </div>
                  }
                />
                <TableRow
                  value={
                    <button
                      className={`p-1 rounded-md text-[10px] ${claim.status === "PENDING" ? "bg-lime-600 hover:bg-lime-500 text-white" : "bg-white border text-black"} `}
                      disabled={claim.status !== "PENDING" || isSubmitting}
                      onClick={() => handleClaimSolve(claim)}
                    >
                      {claim.status === "PENDING" ? "Resolve" : "Solved"}
                    </button>
                  }
                />
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Claims;
