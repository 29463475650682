import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { AiTwotoneEdit } from "react-icons/ai";
import { ImBlocked } from "react-icons/im";
import { useAuthHeader } from "react-auth-kit";
import { IoChevronBackSharp } from "react-icons/io5";
import ProfileAvatar from "../../components/Images/ProfileAvatar";
import { toast } from "react-toastify";
import UserReviews from "./UserReviews";
import Claims from "../../components/Claims/Claims";
import UserBusinessPlaces from "./UserBusinessPlaces";

function UserDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const { data: user, loading } = useFetch(`/v1/admin/users/${params.userId}`);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (user) {
      setBlocked(user.isBlocked);
      setDeleted(user.isDeleted);
    }
  }, [user]);

  const handleUserBlock = useCallback(
    async (userBlocked) => {
      setIsSubmitting(true);
      try {
        const action = userBlocked ? "unblock" : "block";
        const url = `${process.env.REACT_APP_SERVER_URI}/v1/admin/users/${user.id}/${action}`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: authHeaderValue,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          setBlocked(!userBlocked);
        } else {
          toast.error("Couldn't block user: " + response.status);
        }
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setIsSubmitting(false);
      }
    },
    [authHeaderValue, user],
  );

  return loading ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-6xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={"/users"}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>USER:</span>
            <span>{user.id}</span>
          </div>
        </div>
        {!deleted && (
          <div className="flex items-center gap-4">
            <button
              onClick={() => handleUserBlock(blocked)}
              disabled={isSubmitting}
              className={`block-btn`}
            >
              <ImBlocked />
              {blocked ? "UNBLOCK" : "BLOCK"}
            </button>
            <button
              onClick={() =>
                navigate(`/users/${user.id}/edit`, { state: { user: user } })
              }
              className="edit-btn"
            >
              <AiTwotoneEdit />
              EDIT
            </button>
          </div>
        )}
      </div>
      <div className=" grid grid-cols-5 gap-8">
        <div className="flex flex-col col-span-5 xl:col-span-3 gap-8">
          <div className="bg-white p-6 rounded-md shadow-md flex-col py-16">
            <div className="flex flex-col justify-center items-center">
              <ProfileAvatar imageUrl={user.photoUrl} />
              <div className="flex items-center gap-2 mt-6 text-sm">
                <div
                  className={`p-1 rounded-full ${deleted ? "bg-red-500" : blocked ? "bg-yellow-500" : "bg-green-500"}`}
                />
                {deleted ? "Deleted" : blocked ? "Blocked" : "Active"}
              </div>
              <div className="font-semibold text-xl text-gray-700 mt-2">
                {user.firstName || user.lastName
                  ? `${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`
                  : `${user.id}`}
              </div>
              <div className="text-gray-600 text-sm mt-1">{user.email}</div>
              <div className="flex gap-1 mt-6">
                {user.roles.map((role, index) => (
                  <span
                    className={`${role.id === 1 ? "bg-sky-100" : role.id === 2 ? "bg-red-100" : "bg-lime-100"} text-sm rounded-lg px-2`}
                    key={index}
                  >
                    {role.name}
                  </span>
                ))}
              </div>
              <div className="mx-auto mt-6 grid max-w-94 grid-cols-3 rounded-md border border-stroke py-2 shadow-sm">
                <div className="flex items-center justify-center gap-1 border-r border-stroke px-4">
                  <span className="font-semibold text-sm text-black">
                    {user.reviewsCount}
                  </span>
                  <span className="text-xs">Reviews</span>
                </div>
                <div className="flex items-center justify-center gap-1 border-r border-stroke px-4">
                  <span className="font-semibold text-sm text-black">
                    {user.followerCount}
                  </span>
                  <span className="text-xs">Followers</span>
                </div>
                <div className="flex items-center justify-center gap-1 border-stroke px-4">
                  <span className="font-semibold text-sm text-black">
                    {user.followingCount}
                  </span>
                  <span className="text-xs">Following</span>
                </div>
              </div>
              <div className="max-w-sm flex flex-col mt-6 text-center">
                <span className="text-xs text-gray-500">About me</span>
                <p className="text-sm mt-2 text-gray-600">{user.bio}</p>
              </div>
            </div>
          </div>
          <div className="rounded-md shadow-md bg-white p-8">
            <Claims reports={user.reports} type="users" />
          </div>
        </div>
        <div className="flex flex-col gap-8 col-span-5 xl:col-span-2">
          <div className="flex flex-col gap-8">
            {user.businessPlaces && (
              <div className="rounded-md shadow-md bg-white p-8 overflow-y-auto max-h-xs">
                <UserBusinessPlaces places={user.businessPlaces} />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-8">
            <div className="rounded-md shadow-md bg-white p-8 overflow-y-auto max-h-xs">
              <UserReviews userId={user.id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
