import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import useFetch from "../../hooks/useFetch";
import { IoChevronBackSharp } from "react-icons/io5";
import { AiOutlineEye, AiTwotoneEdit } from "react-icons/ai";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { PiHandsClappingLight } from "react-icons/pi";
import { toast } from "react-toastify";

function ArticleDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const { data: article, loading } = useFetch(
    `/v1/admin/articles/${params.articleId}`,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  useEffect(() => {
    if (article) {
      setIsPrivate(article.isPrivate);
      setIsVisible(article.isVisible);
    }
  }, [article]);

  const handleArticleVisibility = useCallback(
    async (isArticleVisible) => {
      setIsSubmitting(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URI}/v1/admin/articles/${article.id}`,
          {
            method: "PATCH",
            body: JSON.stringify({ isVisible: !isArticleVisible }),
            headers: {
              Authorization: authHeaderValue,
              "Content-Type": "application/json",
            },
          },
        );
        if (response.ok) {
          setIsVisible(!isArticleVisible);
        }
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setIsSubmitting(false);
      }
    },
    [authHeaderValue, article],
  );

  const handleArticlePrivacy = useCallback(
    async (isArticlePrivate) => {
      setIsSubmitting(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URI}/v1/admin/articles/${article.id}`,
          {
            method: "PATCH",
            body: JSON.stringify({ isPrivate: !isArticlePrivate }),
            headers: {
              Authorization: authHeaderValue,
              "Content-Type": "application/json",
            },
          },
        );
        if (response.ok) {
          setIsPrivate(!isArticlePrivate);
        }
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setIsSubmitting(false);
      }
    },
    [authHeaderValue, article],
  );

  return loading ? (
    <span>Loading...</span>
  ) : (
    <div className="max-w-xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={"/articles"}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>ARTICLE:</span>
            <span>{article.id}</span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={() => handleArticleVisibility(isVisible)}
            disabled={isSubmitting}
            className={"block-btn"}
          >
            <AiOutlineEye />
            {isVisible ? "HIDDEN" : "VISIBLE"}
          </button>
          <button
            onClick={() => handleArticlePrivacy(isPrivate)}
            disabled={isSubmitting}
            className={"block-btn"}
          >
            <RiGitRepositoryPrivateLine />
            {isPrivate ? "PUBLIC" : "PRIVATE"}
          </button>
          <button
            onClick={() =>
              navigate(`/articles/${article.id}/edit`, {
                state: { article: article },
              })
            }
            className={`edit-btn`}
          >
            <AiTwotoneEdit />
            EDIT
          </button>
        </div>
      </div>
      <div className="bg-white p-8 pb-12 rounded-md shadow-md flex-col">
        <div className="flex justify-between items-center">
          <div className="flex gap-4">
            <div className="text-xs flex gap-1 items-center">
              <PiHandsClappingLight />
              {article.likes}
            </div>
            <div className=" gap-2 flex items-center">
              <span className="text-sm">{article.country}</span>
              <span className="text-xs">({article.countryCode})</span>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex items-center gap-2  text-sm">
              <div
                className={`p-1 rounded-full ${isVisible ? "bg-green-500" : "bg-orange-500"}`}
              />
              {isVisible ? "Visible" : "Hidden"}
            </div>
            <div className="flex items-center gap-2  text-sm">
              <div
                className={`p-1 rounded-full ${isPrivate ? "bg-orange-500" : "bg-green-500"}`}
              />
              {isPrivate ? "Private" : "Public"}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-8">
          <div className="w-2/3 h-52 border rounded-lg">
            <div
              style={{ backgroundImage: `url(${article.image})` }}
              className="w-full h-full relative rounded-lg bg-center bg-cover"
            />
          </div>
          <div className="flex flex-col text-center font-semibold text-xl text-gray-700 mt-8">
            {article.title}
            <span className="text-[10px]">
              ({new Date(article.createdAt).toLocaleDateString()})
            </span>
          </div>
          <div className="w-full text-start text-justify mt-4">
            {article.text.split("\n").map((line, index) => (
              <p
                key={index}
                className={`indent-8 whitespace-pre-line text-justify break-words`}
              >
                {line}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleDetails;
