import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";

function useFetch(url) {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URI}${url}`,
          {
            headers: { Authorization: authHeaderValue },
            signal: controller.signal,
          },
        );
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
    return () => controller.abort();
  }, [url, authHeaderValue]);

  return { data, error, loading };
}

export default useFetch;
