import React, { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoChevronBackSharp } from "react-icons/io5";
import { BsSaveFill } from "react-icons/bs";
import CollectionEditSection from "../../components/Collections/CollectionEditSection";

function CreateCollection() {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [collection, setCollection] = useState({
    name: "",
    image: "",
    countryCode: "",
    placeIds: [],
    isPublic: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/v1/admin/places/collections/system`,
        {
          method: "POST",
          body: JSON.stringify({
            ...collection,
            countryCode: collection.countryCode.value,
            placeIds: collection.placeIds.map((placeId) => placeId.value),
          }),
          headers: {
            Authorization: authHeaderValue,
            "Content-Type": "application/json",
          },
        },
      );
      if (response.ok) {
        const responseData = await response.json();
        toast.success("Collection has been created");
        navigate(`/collections/${responseData.id}`);
      } else {
        toast.error("Couldn't create collection: " + response.status);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-xl mx-auto">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex items-center">
          <Link
            to={"/collections"}
            className="text-black mr-2 flex items-center justify-center gap-1 duration-300 hover:bg-gray-300 rounded-lg text-sm p-1"
          >
            <IoChevronBackSharp />
            BACK
          </Link>
          <div className="border-l border-gray-500 pl-4 flex items-center font-bold text-lg gap-1">
            <span>CREATE COLLECTION</span>
          </div>
        </div>
        <div className="flex items-center">
          <button
            form="create-collection-form"
            type="submit"
            disabled={isSubmitting}
            className={`edit-btn`}
          >
            <BsSaveFill />
            CREATE
          </button>
        </div>
      </div>
      <div className="bg-white p-8 pb-12 rounded-md shadow-md">
        <form id="create-collection-form" onSubmit={handleSubmit}>
          <CollectionEditSection
            collection={collection}
            setCollection={setCollection}
          />
        </form>
      </div>
    </div>
  );
}

export default CreateCollection;
