import React from "react";
import { TbWorldWww } from "react-icons/tb";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTelegram,
} from "react-icons/bs";
import { BiLogoTiktok } from "react-icons/bi";
import { AiFillPhone } from "react-icons/ai";

function Contacts({ contacts }) {
  let socials = [];
  if (contacts.website) {
    socials.push(
      <SocialItem
        key={1}
        link={contacts.website}
        icon={<TbWorldWww size={24} />}
      />,
    );
  }
  if (contacts.instagram) {
    socials.push(
      <SocialItem
        key={2}
        link={contacts.instagram}
        icon={<BsInstagram size={24} />}
      />,
    );
  }
  if (contacts.facebook) {
    socials.push(
      <SocialItem
        key={3}
        link={contacts.facebook}
        icon={<BsFacebook size={24} />}
      />,
    );
  }
  if (contacts.telegram) {
    socials.push(
      <SocialItem
        key={4}
        link={contacts.telegram}
        icon={<BsTelegram size={24} />}
      />,
    );
  }
  if (contacts.linkedin) {
    socials.push(
      <SocialItem
        key={5}
        link={contacts.linkedin}
        icon={<BsLinkedin size={24} />}
      />,
    );
  }
  if (contacts.tiktok) {
    socials.push(
      <SocialItem
        key={6}
        link={contacts.tiktok}
        icon={<BiLogoTiktok size={24} />}
      />,
    );
  }
  if (contacts.phone) {
    socials.push(
      <SocialItem
        key={7}
        link={contacts.phone}
        icon={<AiFillPhone size={24} />}
      />,
    );
  }
  return socials;
}

const SocialItem = ({ icon, link }) => (
  <div className="flex cursor-pointer group relative p-1 text-gray-700 bg-gray-50 shadow-md duration-200 hover:bg-gray-200 rounded-full items-center w-10 h-10 justify-center">
    {icon}
    <div className="absolute z-10 rounded-md text-nowrap py-0.5 px-1 bg-gray-700/80 text-white -bottom-6 text-[8px] duration-200 scale-0 group-hover:scale-100">
      {link}
    </div>
  </div>
);

export default Contacts;
