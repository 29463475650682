import { HiOutlinePhotograph } from "react-icons/hi";
import React from "react";

function ProfileAvatar({ imageUrl }) {
  return (
    <>
      {imageUrl ? (
        <div className="flex h-32 w-32">
          <div
            style={{ backgroundImage: `url(${imageUrl})` }}
            className="w-full h-full relative rounded-full bg-center bg-cover ring-8 ring-gray-200"
          ></div>
        </div>
      ) : (
        <div
          className={`flex items-center justify-center h-32 w-32 ${!imageUrl && "bg-gray-200 rounded-full"}`}
        >
          <HiOutlinePhotograph className="w-2/3 h-2/3" />
        </div>
      )}
    </>
  );
}

export default ProfileAvatar;
