import React from "react";
import PlaceMap from "./PlaceMap";

function LocationUpdate({ entity, setEntity }) {
  const handeLocationChange = (event) => {
    const { name, value } = event.target;
    setEntity((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  const handlePointerChange = (event) => {
    const { lat: clickedLat, lng: clickedLon } = event.latlng;
    setEntity((prevEntity) => ({
      ...prevEntity,
      lat: clickedLat,
      lon: clickedLon,
    }));
  };

  return !entity ? (
    <span>Loading...</span>
  ) : (
    <>
      <div className="mt-8 flex flex-col gap-3">
        <div className="font-semibold text-sm text-center">LOCATION</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="relative">
            <label className="block text-[10px] text-gray-700">Latitude</label>
            <input
              type="text"
              name="lat"
              required
              onChange={handeLocationChange}
              value={entity.lat}
              className="custom-input"
              placeholder="Latitude"
            />
          </div>
          <div className="relative">
            <label className="block text-[10px] text-gray-700">Longitude</label>
            <input
              type="text"
              name="lon"
              required
              onChange={handeLocationChange}
              value={entity.lon}
              className="custom-input"
              placeholder="Longitude"
            />
          </div>
        </div>
      </div>
      <div className="mt-4 w-full h-72">
        <PlaceMap
          lat={entity.lat}
          lon={entity.lon}
          onPointerChange={handlePointerChange}
        />
      </div>
    </>
  );
}

export default LocationUpdate;
